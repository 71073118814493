const classes = [
  {
    path: '/classes',
    name: 'classes',
    component: () => import('@/views/classes/Classes.vue'),
    meta: {
      layout: 'content',
      resource: 'ClassInstance',
      action: 'read',
    },
  },
  {
    path: '/add-classes',
    name: 'add-classes',
    component: () => import('@/views/classes/AddClasses.vue'),
    meta: {
      layout: 'content',
      resource: 'ClassInstance',
      action: 'read',
    },
  },
  {
    path: '/edit-classes/:uuid',
    name: 'edit-classes',
    component: () => import('@/views/classes/EditClasses.vue'),
    meta: {
      layout: 'content',
      resource: 'ClassInstance',
      action: 'read',
    },
  },
]
export default classes
