const student = [
  {
    path: '/student',
    name: 'student',
    component: () => import('@/views/student/Student.vue'),
    meta: {
      layout: 'content',
      resource: 'StudentInstance',
      action: 'read',
    },
  },
]
export default student
