<template>
  <div
    id="scrollbar"
    class="packet-option"
  >
    <v-container>
      <div
        v-if="!pkg"
        class="text-center"
      >
        <v-progress-circular
          color="primary"
          class="mx-auto mb-3"
          :size="40"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-else
        class="packet-header d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row px-1 py-4"
      >
        <div class="header-left d-flex">
          <ul>
            <li>Nama Paket</li>
            <li>Deskripsi</li>
          </ul>
          <ul class="ml-4">
            <li class="font-weight-medium">
              : {{ pkg.name }}
            </li>
            <li class="font-weight-medium">
              : {{ pkg.description }}
            </li>
          </ul>
        </div>
        <div class="header-right d-flex ml-sm-10 ml-md-10 ml-lg-10 ml-xl-10">
          <ul class="ml-0 pl-0">
            <li>Harga</li>
            <li v-if="dialog ==='edit'">
              Jumlah Buku
            </li>
          </ul>
          <ul class="ml-4">
            <li class="font-weight-medium">
              : Rp. {{ pkg.price }}
            </li>
            <li
              v-if="dialog === 'edit'"
              class="font-weight-medium"
            >
              : {{ pkg.total_library }}
            </li>
          </ul>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="packet-title d-flex align-center justify-space-between px-1 mt-4">
        <v-col
          cols="6"
          class="pl-0"
        >
          <h1>Pilih Buku</h1>
          <p>Cari dan pilih buku sesuai kebutuhan paket</p>

          <div class="ml-4 d-flex align-center">
            Select All By Category : <v-switch
              v-model="isSelectAll"
              class="ml-4"
              @change="checkButton"
            ></v-switch>
          </div>
        </v-col>
        <v-col
          cols="6"
          class="pr-0"
        >
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
            dense
            outlined
            class=""
          ></v-text-field>
        </v-col>
        <div>
        </div>
      </div>
      <v-row class="px-1">
        <v-col
          md="6"
          cols="12"
        >
          <v-card>
            <v-card-title>
              <h4 class="font-weight-medium">
                Kategori Buku
              </h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-list
              height="400"
              class="overflow-auto"
              rounded
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <a
                      @click="clickAll"
                    >Semua Kategori</a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-progress-circular
                v-if="!categories.length"
                color="primary"
                class="px-10"
                :size="45"
                indeterminate
              ></v-progress-circular>
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-treeview
                      activatable
                      rounded
                      hoverable
                      color="primary"
                      :items="categories"
                      item-key="uuid"
                      item-text="name"
                      item-children="sub_categories"
                    >
                      <template
                        slot="label"
                        slot-scope="{ item }"
                      >
                        <div :class="isLoadingLibrary ? 'disable-wrap' : ''">
                          <a
                            class="d-flex justify-space-between align-center"
                            :class="isLoadingLibrary ? 'disable' : ''"
                            @click.prevent="changeLibraryList(item.uuid)"
                          >
                            <span
                              class="text-truncate"
                              style="max-width: 150px"
                            >{{ item.name }}</span>
                            <v-chip
                              color="primary"
                            >
                              {{ item.total_libraries }} Buku
                            </v-chip>
                          </a>
                        </div>
                      </template>
                    </v-treeview>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-card>
            <v-card-title>
              <h4 class="font-weight-medium">
                Pilih Buku
              </h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table
              class="overflow-auto"
            >
              <thead style="border-top: none">
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    No.
                  </th>
                  <th class="text-left">
                    Type Library
                  </th>
                  <th class="text-left">
                    Nama Buku
                  </th>
                </tr>
              </thead>
            </v-simple-table>
            <v-simple-table
              id="scrollbar2"
              height="346"
              class="overflow-auto"
            >
              <template
                v-slot:default
              >
                <tbody>
                  <div
                    v-if="isLoadingLibrary "
                    class="d-block text-center"
                  >
                    <v-progress-circular
                      class="mt-5"
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                  <tr
                    v-for="(library, index) in libraries"
                    v-else
                    :key="index"
                  >
                    <td>
                      <v-checkbox
                        v-model="librariesToAdd"
                        :value="library.uuid"
                        @change="handleCheckboxChange(library.uuid, librariesToAdd)"
                      >
                      </v-checkbox>
                    </td>
                    <td>{{ index+1 }}</td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <v-chip
                          outlined
                          :class="`${getBadgeType(library.type)} text-capitalize`"
                        >
                          {{ library.type }}
                        </v-chip>
                      </div>
                    </td>
                    <td>{{ library.name }}</td>
                  </tr>
                </tbody>
                <infinite-loading
                  v-if="isInfinite"
                  spiner="circles"
                  @infinite="infiniteHandler"
                >
                  <div slot="spinner"></div>

                  <div slot="no-more">
                  </div>
                  <div slot="no-results">
                  </div>
                </infinite-loading>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="px-1 pb-5">
        <v-col
          md="6"
          cols="12"
        >
          <v-card class="">
            <v-card-text>
              <h4>Buku yang dipilih : {{ librariesToAdd.length ? librariesToAdd.length : 0 }} dari {{ totalBook }}</h4>
              <p>Kamu Telah memilih buku sesuai jumlah buku dari paket</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <div class="notice">
            <h3>Keterangan :</h3>
            <div class="d-flex justify-space-between">
              <div
                v-for="(notice) in notices"
                :key="notice.title"
              >
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="notice.isChecked"
                    :disabled="notice.disable"
                  ></v-checkbox>
                  <small>{{ notice.title }}</small>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-center mb-1">
        <v-btn
          color="primary"
          class="mx-2"
          large
          :disabled="!librariesToAdd.length"
          :loading="isLoadingButton"
          @click="dialog ==='edit' ? update() : save()"
        >
          Simpan
        </v-btn>
        <v-btn
          color="primary"
          outlined
          class="mx-2"
          large
          @click="$emit('prev-tab')"
        >
          Sebelumnya
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import currencyToIntFormat from '@/utils/currency/currencyToIntFormat'
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'
import { mdiBook, mdiBookMusic, mdiFileVideo } from '@mdi/js'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    InfiniteLoading,
  },
  props: {
    pkg: {
      type: Object,
      default: () => null,
    },
    dialog: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isInfinite: true,
      isSelectAll: false,
      icons: {
        mdiBook,
        mdiBookMusic,
        mdiFileVideo,
      },
      packetOptionDialog: false,
      categories: [],
      libraries: [],
      librariesSelected: [],
      isLoadingLibrary: false,
      isLoadingCategory: false,
      isLoadingButton: false,
      isLoadingPacket: false,
      searchDebounce: '',
      timeout: null,
      packet: {
        uuid: null,
        name: '',
        description: '',
        level: '',
        total_library: 0,
        price: 0,
        library: [],
      },
      notices: [
        { title: 'Buku yang akan dibeli', disable: false, isChecked: true },
        { title: 'Buku yang sudah dibeli', disable: true, isChecked: true },
        { title: 'Buku yang tidak dibeli', disable: true, isChecked: false },
      ],
      serviceCategory: 'category',
      serviceLibrary: 'library',
      serviceLibraryCategory: 'categorylibrary',
      servicePackageLibrary: 'packagelibrary',
      servicePackage: 'package',
      totalBook: 0,
      librariesToAdd: [],
      librariesToRemove: [],
      totalItemsLib: 0,
      totalPagesLib: 100,
      pageLib: 1,
      pageLibCategory: 0,
      changeUuid: null,
    }
  },
  computed: {
    price() {
      return toCurrencyFormat(this.packet.price)
    },
    search: {
      get() {
        return this.searchDebounce
      },
      set(val) {
        if (val.length > 3 || val.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.searchDebounce = val
            this.listLibrary({ library: this.searchDebounce })
          }, 500)
        }
      },
    },
  },
  watch: {
    pkg() {
      if (this.pkg.uuid.length && this.dialog === 'edit') {
        this.getSelectedLibraries()
      }
    },
  },
  mounted() {
    this.listCategory()
    if (this.pkg.uuid.length && this.dialog === 'edit') {
      this.getSelectedLibraries()
    }
    this.listLibrary()
  },
  methods: {
    async infiniteHandler($state) {
      this.isLoadingLibrary = true

      await this.$services.ApiServices.list(this.serviceLibrary, {
        per_page: 100,
        page: this.pageLib,
      }).then(({ data }) => {
        if (data.data.length) {
          this.pageLib += 1

          this.libraries = [...this.libraries, ...data.data]
          this.libraries = this.libraries.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          this.totalBook = data.meta.total

          $state.loaded()
        } else {
          $state.complete()
        }
      })
      this.isLoadingLibrary = false
    },
    async save() {
      this.isLoadingButton = true
      this.pkg.total_book = this.librariesToAdd.length
      this.pkg.price = currencyToIntFormat(this.pkg.price)
      await this.$services.ApiServices.add(this.servicePackage, this.pkg).then(
        ({ data }) => {
          this.packet = data.data
          this.isLoadingButton = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )

      this.formData = new FormData()
      this.librariesToAdd.forEach(item => {
        this.formData.append('library_uuid[]', item)
      })
      this.formData.append('package_uuid', this.packet.uuid)
      await this.$services.ApiServices.add(this.servicePackageLibrary, this.formData).then(
        res => null,
        err => console.error(err),
      )
      this.showSnackbar({
        text: 'Add Package Has Successfully',
        color: 'success',
      })
      this.isLoadingButton = false
      this.librariesToAdd = []
      this.$emit('close-dialog')
      this.$emit('success-save')
    },

    async update() {
      this.isLoadingButton = true
      this.pkg.total_book = this.librariesToAdd.length
      this.pkg.price = currencyToIntFormat(this.pkg.price)
      await this.$services.ApiServices.update(this.servicePackage, this.pkg, this.pkg.uuid).then(
        ({ data }) => {
          this.packet = data.data
        },
        err => console.error(err),
      )

      this.formData = new FormData()
      this.librariesToAdd.forEach(item => {
        this.formData.append('library_uuid[]', item)
      })
      this.formData.append('package_uuid', this.packet.uuid)
      await this.$services.ApiServices.add(this.servicePackageLibrary, this.formData).then(
        res => null,
        err => console.error(err),
      )
      this.showSnackbar({
        text: 'Update Package Has Successfully',
        color: 'success',
      })
      this.isLoadingButton = false
      this.librariesToAdd = []
      this.$emit('close-dialog')
      this.$emit('success-save')
    },

    async listCategory() {
      await this.$services.ApiServices.list(this.serviceCategory, { per_page: 'all' }).then(
        ({ data }) => {
          this.categories = data.data
        },
        err => console.error(err),
      )
    },
    async getSelectedLibraries() {
      this.librariesToAdd = []

      await this.$services.ApiServices.get(this.servicePackage, this.pkg.uuid).then(
        ({ data }) => {
          data.data.library.map(item => this.librariesToAdd.push(item.uuid))

          this.pkg.total_library = this.librariesToAdd.length
        },
        err => console.error(err),
      )
    },
    async listLibrary(params = {}) {
      this.isLoadingLibrary = true
      // this.getSelectedLibraries()
      await this.$services.ApiServices.list(this.serviceLibrary, {
        ...params,
        per_page: 100,
        // page: this.pageLib,
      }).then(
        async ({ data }) => {
          this.libraries = data.data.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))

          this.totalItemsLib = data.meta.total
          this.totalPagesLib = data.meta.per_page
          this.totalBook = data.meta.total
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },

    async changeLibraryList(uuid) {
      this.isInfinite = false
      this.isLoadingLibrary = true

      await this.$services.ApiServices.get(this.serviceLibraryCategory, uuid, { per_page: 'all' }).then(
        ({ data }) => {
          const librariesData = data.data
          librariesData.forEach(item => {
            item.selected = this.librariesToAdd.includes(item.uuid)
            if (!item.selected && this.isSelectAll) this.librariesToAdd.push(item.uuid)
          })
          this.libraries = data.data.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          this.totalItemsLib = data.meta.total
          this.totalPagesLib = data.meta.per_page
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },
    clickAll() {
      this.isInfinite = true
      this.listLibrary()
    },
    getBadgeType(type) {
      if (type === 'ebook') {
        return 'v-chip-light-bg warning--text'
      }
      if (type === 'audio') {
        return 'v-chip-light-bg success--text'
      }

      return 'v-chip-light-bg primary--text'
    },
    checkButton() {
      if (!this.isSelectAll) {
        this.librariesToAdd = []
        this.total_book = this.librariesToAdd.length
      }
    },
    handleCheckboxChange(data, selected) {},
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style scoped>
.packet-header .header-left ul {
  padding-left: 0;
}
.disable {
  /* cursor: not-allowed; */
  pointer-events: none;
}

.disable-wrap {
  cursor: not-allowed;
}
.packet-header .header-left ul li,
.packet-header .header-right ul li {
  list-style: none;
  margin-bottom: 1rem;
}
.v-application::v-deep a {
  color: #746d7d;
  text-decoration: none;
}
</style>
