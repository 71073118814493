const attendance = [
  {
    path: '/attendance-employee',
    name: 'attendance-employee',
    component: () => import('@/views/attendance/AttendanceEmployee.vue'),
    meta: {
      layout: 'content',
      resource: 'AttendanceInstanceEmployee',
      action: 'read',
    },
  },
  {
    path: '/attendance-student',
    name: 'attendance-student',
    component: () => import('@/views/attendance/AttendanceStudent.vue'),
    meta: {
      layout: 'content',
      resource: 'AttendanceInstanceStudent',
      action: 'read',
    },
  },
]
export default attendance
