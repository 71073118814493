<template>
  <div>
    <v-card>
      <v-card-title>
        <h2 class="text-xl font-weight-medium">
          Ubah Password
        </h2>
      </v-card-title>
      <v-card-text>
        <div class="mb-3">
          <v-alert
            text
            color="warning"
          >
            <span class="d-block font-weight-medium mb-1">Pastikan persyaratan-persyaratan ini terpenuhi</span>
            <span class="d-block">Minimal 8 karakter, huruf besar & simbol </span>
          </v-alert>
        </div>

        <div>
          <p class="d-block font-weight-bold ">
            Password Lama
          </p>
          <v-row>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <v-form
                ref="form"
                v-model="form.valid"
                lazy-validation
              >
                <div>
                  <v-text-field
                    v-model="oldPassword"
                    label="Password Lama"
                    outlined
                    dense
                    :append-icon="isOldPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :type="isOldPasswordVisible ? 'text' : 'password'"
                    @click:append="isOldPasswordVisible = !isOldPasswordVisible"
                  ></v-text-field>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </div>
        <div>
          <p class="d-block font-weight-bold mt-1">
            Password Baru
          </p>
          <v-row>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <v-form
                ref="form"
                v-model="form.valid"
                lazy-validation
              >
                <div>
                  <v-text-field
                    v-model="password"
                    label="Password Baru"
                    outlined
                    dense
                    :rules="[ rules.capital, rules.special, rules.min]"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </div>
              </v-form>
            </v-col>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-text-field
                  v-model="passwordConfirm"
                  label="Konfirmasi Password Baru"
                  outlined
                  :rules="[rules.passwordMatch]"
                  dense
                  :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions class="pb-7">
        <div>
          <v-btn
            color="primary"
            :loading="isLoadingButton"
            :disabled="isLoadingButton"
            @click="changePassword"
          >
            Ubah Password
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  name: 'UserTabSecurity',
  props: {
    instanceUuid: {
      type: String,
      default: () => '',
    },
    userUuid: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      form: {
        required: true,
      },
      isLoadingButton: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      isOldPasswordVisible: false,
      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      oldPassword: '',
      password: '',
      passwordConfirm: '',
      rules: {
        min: v => v.length >= 8 || 'Min 8 characters',
        passwordMatch: value => value === this.password || "The password you entered don't match",
        capital: value => (value && /[A-Z]{1}/.test(value)) || 'At least one capital latter',
        special: value => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
      },

      serviceInstance: 'instance',
      serviceUser: 'user',
    }
  },
  watch: {
    instanceUuid: {
      handler() {
        this.resetForm()
        if (this.instanceUuid) {
          this.getInstance()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.form.valid = false
  },
  methods: {
    async changePassword() {
      this.isLoadingButton = true
      const data = ''
      const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
      if (!this.$refs.form.validate()) {
        this.showSnackbar({
          text: 'Periksa kembali password anda',
          color: 'error',
        })
        this.isLoadingButton = false

        return
      }
      if (this.password !== this.passwordConfirm) {
        this.showSnackbar({
          text: 'Password anda tidak sama',
          color: 'error',
        })
        this.isLoadingButton = false

        return
      }

      this.dataPassword = {
        password: this.password,
        oldPassword: this.oldPassword,
      }
      await this.$services.ApiServices.update(this.serviceUser, this.dataPassword, this.userUuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Berhasil Mengubah Password Instansi',
            color: 'success',
          })
          this.resetForm()
          this.isLoadingButton = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },
    resetForm() {
      this.password = ''
      this.passwordConfirm = ''
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>
