<template>
  <div class="auth-wrapper auth-v2">
    <div
      class="auth-inner"
      style="background: #00BB8D"
    >
      <v-row
        class="auth-row ma-0"
      >
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <!-- brand logo -->
          <div
            class="mt-8"
          >
            <div class="d-flex justify-center">
              <div>
                <v-img
                  :src="appLogoLogin"
                  max-height="100%"
                  max-width="100%"
                  alt="logo"
                  contain
                  class="justify-center"
                ></v-img>
              </div>
            </div>
          </div>
          <!--/ brand logo -->
          <div class="auth-illustrator-wrapper">
            <!-- <v-img
              class="login-mask-bg"
              :src="require(`@/assets/images/shapes/rectangle-login.svg`)"
            >
            </v-img> -->

            <!-- rounded -->

            <!-- 3d character -->
            <div class="d-flex align-center h-full px-10 pt-10">
              <v-img
                contain
                max-width="100%"
                height="500"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/05.svg`)"
              ></v-img>
            </div>
            <!-- 3d character -->
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <h1 class="text-h4 font-weight-light text--black mb-8">
                    Selamat Datang di
                    <span class="font-weight-bold">TitikBaca Digital</span>
                  </h1>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email atau Username"
                      placeholder="Email atau Username"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      :error-messages="errorMessages.password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <v-checkbox
                        hide-details
                        label="Ingat Saya"
                        class="mt-0"
                      >
                      </v-checkbox>

                      <!-- forget link -->
                      <router-link
                        :to="{name:'auth-forgot-password'}"
                        class="ms-3"
                      >
                        Lupa Password?
                      </router-link>
                    </div>

                    <v-btn
                      :loading="isLoadingButton"
                      :disabled="isLoadingButton"
                      color="primary"
                      type="submit"
                      class="mt-6 mb-7"
                      x-large
                      block
                      width="100%"
                    >
                      Masuk
                    </v-btn>
                    <p class="text-center font-weight-normal text--black mt-6">
                      Belum punya akun? <a class="primary--text">Buat akun baru</a>
                    </p>
                  </v-form>
                  <div class="d-flex align-center py-1">
                    <v-divider></v-divider>
                    <span class="mx-5">Atau</span>
                    <v-divider></v-divider>
                  </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                  >
                    <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>
                <!-- create new account  -->
                <!-- <a
                  class="d-flex align-center v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--x-large py-2"
                  style="width:100%;background:#000"
                  href="https://play.google.com/store/apps/details?id=com.literasia.literasialibrary&hl=in&gl=ID"
                >
                  <div
                    class="mr-4 d-flex align-center"
                  >
                    <img
                      src="../assets/images/logos/gp.png"
                      alt=""
                      class=""
                      height="40"
                      width="40"
                    >
                  </div>
                  <div
                    class="flex"
                  >
                    <h6 class="mb-0 white--text text-left font-weight-light">
                      GET IN ON
                    </h6>
                    <h3 class="mb-0 white--text text-capitalize font-weight-bold text-left">
                      Google Playstore
                    </h3>
                  </div>
                </a> -->
                <!-- <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    New on our platform?
                  </p>
                  <router-link :to="{name:'auth-register'}">
                    Create an account
                  </router-link>
                </v-card-text> -->

                <!-- divider -->
                <!-- <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text> -->

                <!-- socail links -->
                <!-- <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                    @click="AuthProvider(link.provider)"
                  >
                    <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions> -->
              </v-card>
              <v-overlay
                :value="isLoadingGoogle"
                :absolute="false"
              >
                <v-progress-circular
                  :size="100"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
                <p class="mt-5">
                  Google Sign in...
                </p>
              </v-overlay>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store/index'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline, mdiGoogle } from '@mdi/js'
import themeConfig from '@themeConfig'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { getToken } from 'firebase/messaging'

export default {
  setup(props, context) {
    // Template Ref
    const loginForm = ref(null)
    const isLoadingButton = ref(false)
    const isLoadingGoogle = ref(false)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])

    const showSnackbar = snackbarData => {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    }

    const handlePermission = () => {
      Notification.requestPermission().then(async permission => {
        if (permission === 'granted') {
          await getToken(context.root.$messaging, {
            vapidKey: process.env.VUE_APP_SOCIAL_VAPID_KEY,
          })
            .then(currentToken => {
              if (currentToken) {
                context.root.$services.FcmServices.allowNotification(currentToken).then(
                  res => {
                    localStorage.setItem('fcmToken', currentToken)
                  },
                  err => {
                    console.log('error', err)
                  },
                )
              } else {
                console.log('No registration token available. Request permission to generate one.')
              }
            })
            .catch(err => {
              console.log('An error occurred while retrieving token. ', err)
            })
        } else {
          console.log('Notification disallowed')
        }
      })
    }

    const SocialLogin = (provider, response) => {
      isLoadingGoogle.value = true
      context.root.$services.AuthServices.oauthCallback(response).then(
        resp => {
          store.dispatch('auth/googleLogin', resp.data.token).then(
            res => {
              if (localStorage.getItem('user') === 'undefined') localStorage.removeItem('user')
              handlePermission()
              isLoadingButton.value = false
              router.push('/')

              return res.data
            },
            error => {
              console.error('Oops, Unable to login!')
              console.log('error :>> ', error.response)
              errorMessages.value = error.response.data.error
              isLoadingButton.value = false
              showSnackbar({
                text: error.response.data.error ? error.response.data.error : 'Unable to login',
                color: 'error',
              })
            },
          )
        },
        err => {
          console.log(err)
        },
      )
      isLoadingGoogle.value = false
    }

    const AuthProvider = async provider => {
      isLoadingGoogle.value = true
      const bb = this
      await context.root.$auth
        .authenticate(provider)
        .then(response => {
          SocialLogin(provider, response)
        })
        .catch(err => {
          console.log('error', { err })
        })
      isLoadingGoogle.value = false
    }

    const socialLink = [
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
        provider: 'google',
      },
    ]

    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      const data = {
        username: email.value,
        password: password.value,
      }

      isLoadingButton.value = true
      await store
        .dispatch('auth/login', data)
        .then(res => {
          if (localStorage.getItem('user') === 'undefined') localStorage.removeItem('user')

          handlePermission()
          // const newAbilities = JSON.parse(localStorage.getItem('userAbility'))
          // context.root.$ability.update(newAbilities)
          router.push('/').catch(error => {
            console.info(error.message)
          })
          // window.location.href = '/'
        })
        .catch(error => {
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error)
          errorMessages.value = error.message
          isLoadingButton.value = false
        })
      isLoadingButton.value = false
    }

    return {
      handleFormSubmit,
      // userAbility,
      isPasswordVisible,
      email,
      password,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,
      appLogoLogin: themeConfig.app.logoLogin,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
      isLoadingButton,
      isLoadingGoogle,

      // social login
      AuthProvider,
      SocialLogin,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

.login-mask-bg {
  position: absolute;
  bottom: 0;
}
</style>
