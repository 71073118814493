export default {
  'Attendance Employee': 'Absensi Pegawai',
  'Attendance Student': 'Absensi Siswa',
  Class: 'Kelas',
  'Daily Free Book': 'Buku Gratis Harian',
  'Dashboard Admin': 'Dashboard Admin',
  'Dashboard Instance': 'Dashboard Instansi',
  Employee: 'Pegawai',
  Instance: 'Instansi',
  Library: 'Perpustakaan',
  Packet: 'Paket Buku',
  Profile: 'Profil',
  Slider: 'Slider',
  Student: 'Siswa',
  User: 'Pengguna',
}
