import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/company'

export default {
  get(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${uuid}/users${paramsString}`)
  },
}
