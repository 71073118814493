<template>
  <div>
    <div class="d-flex justify-start align-center mb-8">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mr-2"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <div v-if="isLoadingInstance">
          <v-progress-circular
            color="primary"
            :size="30"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <span class="d-block text-caption text-capitalize black--text">{{ instance.company_types_uuid.type }}</span>
          <span
            class="d-block text-subtitle-1 font-weight-bold black--text"
            style="line-height: 1.5rem !important"
          >
            {{ instance.name }}
          </span>
        </div>
      </div>
    </div>

    <v-card>
      <div>
        <v-container class="custom-container pa-6 mx-0">
          <v-row class="align-center mb-4">
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              cols="12"
            >
              <h2>Pengguna</h2>
              <small
                style="white-space: normal;"
                class="dark--text"
              >Halaman untuk menampilkan daftar pengguna</small>
            </v-col>
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="6"
              cols="12"
              class="py-3 offset-md-1"
            >
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
                dense
                outlined
                @input="searchHandler($event)"
              ></v-text-field>
            </v-col>
            <v-col
              xl="3"
              lg="3"
              md="3"
              sm="6"
              cols="12"
              class="py-3"
            >
              <v-btn
                color="primary"
                outlined
                width="100%"
                large
                @click="printRecap()"
              >
                Cetak Rekapan
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="my-4">
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-menu
                  v-model="start_date"
                  :close-on-content-click="true"
                  nudge-right="0"
                  nudge-top="20"
                  max-width="100%"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="recap.start_date"
                      label="Tanggal Mulai"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="autofillStartDate"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="recap.start_date"
                    :first-day-of-week="0"
                    locale="id-id"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-menu
                  v-model="end_date"
                  :close-on-content-click="true"
                  nudge-right="0"
                  nudge-top="20"
                  max-width="100%"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="recap.end_date"
                      label="Tanggal Berakhir"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      :disabled="isDisabled"
                      v-on="on"
                      @click="autofillEndDate(recap.start_date)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="recap.end_date"
                    :first-day-of-week="0"
                    locale="id-id"
                    :min="minEndDate"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="6"
              cols="12"
              class="py-0"
            >
              <div>
                <v-btn
                  color="primary"
                  dense
                  large
                  width="100%"
                  @click="getUser()"
                >
                  Tampilkan
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-data-table
        :page="page"
        :headers="headers"
        :items="users"
        :loading="isLoadingUser"
        :server-items-length="totalItems"
        :total-pages.sync="totalPages"
        :search="search"
        hide-default-footer
        class="elevation-0 table-nowrap"
      ></v-data-table>
      <div class="d-flex justify-space-between pt-2 pb-2">
        <div class="ml-4 mt-3">
          <h5>Total item: {{ totalItems }}</h5>
        </div>
        <v-pagination
          v-model="page"
          :length="totalPages"
          total-visible="10"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mdiClose, mdiDotsVertical, mdiTownHall } from '@mdi/js'

export default {
  name: 'UserInstance',
  data() {
    return {
      dialog: '',
      search: '',
      confirmDialog: false,
      confirmCancels: false,
      validateDelete: null,
      isLoadingUser: true,
      isLoadingInstance: false,
      isLoadingButton: false,
      addFormDialog: false,
      editFormDialog: false,
      confirmDeleteDialog: false,
      noAddButton: true,
      printRecapButton: true,
      filterRecapVisitor: true,
      page: 1,
      totalPages: 1,
      totalItems: 1,
      itemsPerPage: 10,
      filterClassUpper: true,
      icons: {
        mdiDotsVertical,
        mdiTownHall,
        mdiClose,
      },
      headers: [
        { text: 'No.', value: 'index' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Jenis Kelamin', value: 'gender' },
        { text: 'Tanggal Lahir', value: 'birthday' },
        { text: 'No. HP', value: 'phone_number' },
        { text: 'Provinsi', value: 'profile.province.name' },
        { text: 'Kecamatan', value: 'profile.district.name' },
        { text: 'Check In', value: 'checkin_at' },
      ],
      users: [],
      instance: {},
      serviceUserCompany: 'usercompany',
      serviceInstance: 'instance',
      instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
      filterQuery: {
        search: '',
      },
      start_date: '',
      end_date: '',
      minEndDate: '',
      isDisabled: true,
      recap: {
        start_date: '',
        end_date: '',
      },
    }
  },
  watch: {
    page: {
      handler() {
        this.getUser(this.filterQuery)
      },
    },
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.page = 1
            this.getUser({ search: this.search })
          }, 500)
        }
      },
    },
  },
  created() {
    this.getDetailInstance()
    this.getUser(this.filterQuery)
  },
  methods: {
    autofillStartDate() {
      this.recap.start_date = new Date().toISOString().substr(0, 10)
      this.isDisabled = false
    },

    autofillEndDate(val) {
      const selectedDate = new Date(val)
      this.recap.end_date = new Date(selectedDate.getTime() + (1 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10)
      this.minEndDate = this.recap.end_date
    },

    async getDetailInstance() {
      this.isLoadingInstance = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.isLoadingInstance = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingInstance = false
    },

    async getUser(params = {}) {
      this.isLoadingUser = true

      await this.$services.ApiServices.get(this.serviceUserCompany, this.instanceUuid, {
        ...params,
        page: this.page,
        start_date: this.recap.start_date,
        end_date: this.recap.end_date,
      }).then(
        ({ data }) => {
          this.users = data.data.map((type, index) => ({
            ...type,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
          this.isLoadingUser = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingUser = false
    },

    async printRecap() {
      await this.$services.RecapServices.recapUser(this.instanceUuid, {
        start_date: this.recap.start_date,
        end_date: this.recap.end_date,
      }).then(
        ({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'recap-visitor.pdf')
          document.body.appendChild(link)
          link.click()
        },
        err => {
          console.error(err)
        },
      )
    },

    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.getUser(this.filterQuery)
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>
<style scoped>
.table-nowrap {
  white-space: nowrap;
}
</style>
