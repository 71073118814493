<template>
  <div>
    <v-card>
      <DataTablePagination
        :page="page"
        :total-items="totalItems"
        :headers="headers"
        :items="types"
        :search="search"
        :total-pages.sync="totalPages"
        :is-loading="isLoadingData"
        title="Tipe Instansi"
        subtitle="Menambahkan data pada tipe instasi"
        add-btn="Tambah Data"
        @add-item="showFormAdd"
        @edit-item="showFormEdit"
        @delete-item="confirmDestroy"
        @change-page="paginationHandler"
        @live-search="searchHandler"
      >
      </DataTablePagination>
    </v-card>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Tipe': 'Edit Tipe'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="type.type"
            label="Tipe Instansi"
            dense
            outlined
          ></v-text-field>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Tipe Instansi"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
// eslint-disable-next-line object-curly-newline
import { mdiBook, mdiBookMusic, mdiFileVideo, mdiMagnify, mdiMapSearch } from '@mdi/js'

export default {
  name: 'InstanceSetting',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
  },
  data() {
    return {
      search: '',
      width: 600,
      confirmCancels: false,
      confirmDialog: false,
      validateDelete: null,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      modalDialog: false,
      dialog: '',
      confirmDeleteDialog: false,
      page: 1,
      totalPages: 1,
      totalItems: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiBook,
        mdiBookMusic,
        mdiFileVideo,
        mdiMapSearch,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Tipe', value: 'type' },
        { text: 'Aksi', value: 'actions' },
      ],
      types: [],
      type: {
        type: '',
      },
      service: 'instancesetting',
      filterQuery: {
        type: '',
      },
      formValid: false,
    }
  },
  watch: {
    type: {
      handler() {
        const valid = []
        const requiredField = ['type']
        Object.entries(this.type).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listType(this.filterQuery)
      },
    },
  },
  created() {
    this.listType()
  },
  methods: {
    showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
      this.resetForm()
    },
    async listType(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
      }).then(
        ({ data }) => {
          this.types = data.data.map((type, index) => ({
            ...type,
            index: index + 1,
          }))
          this.totalPages = data.last_page
          this.totalItems = data.total
          this.isLoadingData = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingData = false
    },
    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.service, this.type).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.listType(this.filterQuery)
          this.resetForm()
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          this.resetForm()
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },

    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update(this.service, this.type, this.type.uuid).then(
        ({ data }) => {
          this.listType(this.filterQuery)
          this.resetForm()
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.type = data.data
        },
        err => console.error(err),
      )
      this.modalDialog = true
    },

    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.type.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.type.uuid).then(
        ({ data }) => {
          console.log(data.result.message)
          this.showSnackbar({
            text: data.result.message,
            color: 'error',
          })
          this.listType(this.filterQuery)
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
      this.confirmDialog = false
    },

    resetForm() {
      this.type.uuid = ''
      this.type.type = ''
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    searchHandler(type) {
      this.filterQuery.type = type
      this.page = 1
      this.listType(this.filterQuery)
    },
  },
}
</script>
