<template>
  <div>
    <div class="d-flex justify-start align-center mb-8">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mr-2"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <div v-if="isLoadingInstance">
          <v-progress-circular
            color="primary"
            :size="30"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <span class="d-block text-caption text-capitalize black--text">{{ instance.company_types_uuid.type }}</span>
          <span
            class="d-block text-subtitle-1 font-weight-bold black--text"
            style="line-height: 1.5rem !important"
          >
            {{ instance.name }}
          </span>
        </div>
      </div>
    </div>

    <v-card
      v-if="!isLoadingInstance"
      id="instance-card"
    >
      <div>
        <v-card-title class="d-block">
          <h2 class="text-xl font-weight-medium">
            Halaman Utama
          </h2>
          <small class="d-block text-caption text-secondary mt-1 mb-42">Halaman Utama Untuk Website</small>
        </v-card-title>
        <v-card-text class="mt-1 pb-0">
          <template>
            <v-card
              v-for="(newSection, key) in newSections"
              id="new-sections"
              :key="key"
              outlined
              class="mb-8"
            >
              <div>
                <v-card-title class="d-flex justify-space-between align-center mb-5">
                  <div>
                    <h4 class="text-h6 font-weight-medium">
                      {{ newSection.title }}
                    </h4>
                    <small class="d-block text-caption text-secondary mt-1 mb-42">{{ newSection.subtitle }}</small>
                  </div>
                </v-card-title>
                <v-card-text class="mt-1">
                  <v-row>
                    <v-col
                      v-if="newSection.type === 'onlytext' || newSection.type === 'textwithpict'"
                      cols="12"
                      class="py-0"
                    >
                      <div class="pb-7">
                        <v-textarea
                          v-model="newSection.description"
                          label="Deskripsi Informasi"
                          hide-details
                          :loading="isLoadingData"
                          :disabled="isLoadingData"
                          outlined
                        ></v-textarea>
                      </div>
                    </v-col>

                    <v-col
                      v-if="newSection.type === 'onlypict' || newSection.type === 'textwithpict'"
                      cols="12"
                      class="py-0"
                    >
                      <div>
                        <FileInput
                          v-model="uploadedImg[key]"
                          label="Gambar"
                          outlined
                          dense
                          :disabled="isLoadingButton"
                          :prependicon="icons.mdiImageMultipleOutline"
                          @input="getFile($event, key)"
                        ></FileInput>
                      </div>

                      <div>
                        <v-row v-if="(showImg[key] === key)">
                          <v-col cols="12">
                            <section class="cropper-area mb-6">
                              <div>
                                <v-alert
                                  dense
                                  text
                                  color="info"
                                  class="alert my-6"
                                >
                                  <v-icon
                                    size="20"
                                    color="#16B9FF"
                                    class="mr-1 font-weight-bold"
                                  >
                                    {{ icons.mdiInformation }}
                                  </v-icon>
                                  <span class="font-weight-medium info--text d-inline-block ms-2">
                                    Sesuaikan gambar dengan ukuran yang telah ditentukan lalu kemudian klik Crop
                                  </span>
                                </v-alert>
                              </div>

                              <div class="d-flex justify-start flex-column">
                                <vue-cropper
                                  :ref="'cropper' + key"
                                  :aspect-ratio="1 / 1"
                                  :src="imgSrc"
                                  preview=".preview"
                                  :auto-crop-area="1"
                                  drag-mode="crop"
                                  :view-mode="3"
                                  :min-container-width="0"
                                  :min-container-height="0"
                                  :crop-box-resizable="false"
                                  :img-style="{ height: '325px' }"
                                />

                                <div class="actions text-center py-2">
                                  <v-btn
                                    color="primary"
                                    outlined
                                    small
                                    class="mr-3 mt-3 btn-tools"
                                    @mousedown="move(key, 0, 15)"
                                    @mouseup="stopMove"
                                  >
                                    <v-icon>
                                      {{ icons.mdiArrowUpThin }}
                                    </v-icon>
                                  </v-btn>

                                  <v-btn
                                    color="primary"
                                    outlined
                                    small
                                    class="mr-3 mt-3 btn-tools"
                                    @mousedown="move(key, 0, -15)"
                                    @mouseup="stopMove"
                                  >
                                    <v-icon>
                                      {{ icons.mdiArrowDownThin }}
                                    </v-icon>
                                  </v-btn>

                                  <v-btn
                                    color="primary"
                                    outlined
                                    small
                                    class="mr-3 mt-3 btn-reset"
                                    @click="reset(key)"
                                  >
                                    <v-icon left>
                                      {{ icons.mdiRefresh }}
                                    </v-icon>
                                    Reset
                                  </v-btn>

                                  <v-btn
                                    color="primary"
                                    small
                                    class="mt-3 btn-crop"
                                    @click="cropImage(key)"
                                  >
                                    <v-icon left>
                                      {{ icons.mdiCrop }}
                                    </v-icon>
                                    Crop
                                  </v-btn>
                                </div>
                              </div>
                            </section>
                          </v-col>
                        </v-row>
                      </div>

                      <div v-if="newSection.image.length">
                        <div class="my-5">
                          <h2
                            class="d-block text-subtitle-1 font-weight-bold black--text my-4"
                            style="line-height: 1.5rem !important"
                          >
                            Gambar yang akan diupload
                          </h2>
                        </div>
                        <v-row class="mb-5">
                          <v-col
                            v-for="(gallery, index) in newSection.image"
                            :key="gallery.uuid"
                            cols="6"
                            md="3"
                            lg="3"
                            xl="3"
                          >
                            <v-card
                              class="overflow-hidden"
                              style="z-index: 0"
                            >
                              <v-card-title
                                class="pa-2 position-absolute"
                                style="right: 0; z-index: 10"
                              >
                                <v-spacer></v-spacer>
                                <v-menu
                                  offset-y
                                  nudge-bottom="5"
                                  nudge-left="60"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="secondary"
                                      v-bind="attrs"
                                      class="image-btn rounded-lg"
                                      v-on="on"
                                      @click="deleteImageTemp(index, key)"
                                    >
                                      <v-icon size="20">
                                        {{ icons.mdiTrashCanOutline }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                </v-menu>
                              </v-card-title>

                              <div>
                                <v-overlay
                                  absolute
                                  :opacity="opacity"
                                ></v-overlay>
                                <v-img
                                  :lazy-src="gallery.image"
                                  :src="gallery.image"
                                  height="200"
                                ></v-img>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>

                      <div v-if="isLoadingData">
                        <v-row class="mb-5">
                          <v-col
                            v-for="(i, index) in 8"
                            :key="index"
                            md="3"
                          >
                            <v-skeleton-loader
                              height="190"
                              type="card-avatar"
                            ></v-skeleton-loader>
                          </v-col>
                        </v-row>
                      </div>

                      <div
                        v-else
                        class="my-5"
                      >
                        <div>
                          <h2
                            class="d-block text-subtitle-1 font-weight-bold black--text mb-4"
                            style="line-height: 1.5rem !important"
                          >
                            Gambar yang sudah diupload
                          </h2>
                        </div>

                        <v-row>
                          <v-col
                            v-for="gallery in newSection.galleries"
                            :key="gallery.uuid"
                            cols="6"
                            md="3"
                            lg="3"
                            xl="3"
                          >
                            <v-card
                              class="overflow-hidden"
                              style="z-index: 0"
                            >
                              <v-card-title
                                class="pa-2 position-absolute"
                                style="right: 0; z-index: 10"
                              >
                                <v-spacer></v-spacer>
                                <v-menu
                                  offset-y
                                  nudge-bottom="5"
                                  nudge-left="60"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="secondary"
                                      v-bind="attrs"
                                      class="image-btn rounded-lg"
                                      v-on="on"
                                      @click="confirmDestroy(gallery.uuid, key)"
                                    >
                                      <v-icon size="20">
                                        {{ icons.mdiTrashCanOutline }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                </v-menu>
                              </v-card-title>
                              <div>
                                <v-overlay
                                  absolute
                                  :opacity="opacity"
                                ></v-overlay>
                                <v-img
                                  :lazy-src="gallery.image"
                                  :src="gallery.image"
                                  height="200"
                                ></v-img>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>

                      <div
                        v-if="newSection.galleries.length < 1"
                        class="d-flex justify-center"
                      >
                        <div class="pa-4 text-center">
                          <v-img
                            src="../../assets/images/vector/cbt-empty.svg"
                            class="mx-8 my-7"
                          ></v-img>
                          <p class="my-7">
                            Belum ada Galeri
                          </p>
                        </div>
                      </div>

                      <v-divider></v-divider>

                      <div class="mt-5">
                        <v-pagination
                          v-if="totalPages"
                          v-model="page"
                          :length="totalPages"
                          total-visible="8"
                          @change="paginationHandler"
                        ></v-pagination>
                      </div>
                    </v-col>

                    <v-col
                      v-if="newSection.type === 'slider'"
                      cols="12"
                      class="py-0"
                    >
                      <div>
                        <FileInput
                          v-model="uploadedImg[key]"
                          label="Gambar"
                          outlined
                          dense
                          :disabled="isLoadingButton"
                          :prependicon="icons.mdiImageMultipleOutline"
                          @input="getFile($event, key)"
                        ></FileInput>
                      </div>

                      <div>
                        <v-row v-if="(showImg[key] === key)">
                          <v-col cols="12">
                            <section class="cropper-area mb-6">
                              <div>
                                <v-alert
                                  dense
                                  text
                                  color="info"
                                  class="alert my-6"
                                >
                                  <v-icon
                                    size="20"
                                    color="#16B9FF"
                                    class="mr-1 font-weight-bold"
                                  >
                                    {{ icons.mdiInformation }}
                                  </v-icon>
                                  <span class="font-weight-medium info--text d-inline-block ms-2">
                                    Sesuaikan gambar dengan ukuran yang telah ditentukan lalu kemudian klik Crop
                                  </span>
                                </v-alert>
                              </div>

                              <div class="d-flex justify-start flex-column">
                                <vue-cropper
                                  :ref="'cropper' + key"
                                  :aspect-ratio="2.91 / 1"
                                  :src="imgSrc"
                                  preview=".preview"
                                  :auto-crop-area="1"
                                  drag-mode="crop"
                                  :view-mode="3"
                                  :min-container-width="0"
                                  :min-container-height="0"
                                  :crop-box-resizable="false"
                                  :img-style="{ height: '325px' }"
                                />

                                <div class="actions text-center py-2">
                                  <v-btn
                                    color="primary"
                                    outlined
                                    small
                                    class="mr-3 mt-3 btn-tools"
                                    @mousedown="move(key, 0, 15)"
                                    @mouseup="stopMove"
                                  >
                                    <v-icon>
                                      {{ icons.mdiArrowUpThin }}
                                    </v-icon>
                                  </v-btn>

                                  <v-btn
                                    color="primary"
                                    outlined
                                    small
                                    class="mr-3 mt-3 btn-tools"
                                    @mousedown="move(key, 0, -15)"
                                    @mouseup="stopMove"
                                  >
                                    <v-icon>
                                      {{ icons.mdiArrowDownThin }}
                                    </v-icon>
                                  </v-btn>

                                  <v-btn
                                    color="primary"
                                    outlined
                                    small
                                    class="mr-3 mt-3 btn-reset"
                                    @click="reset(key)"
                                  >
                                    <v-icon left>
                                      {{ icons.mdiRefresh }}
                                    </v-icon>
                                    Reset
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    small
                                    class="mt-3 btn-crop"
                                    @click="cropImage(key)"
                                  >
                                    <v-icon left>
                                      {{ icons.mdiCrop }}
                                    </v-icon>
                                    Crop
                                  </v-btn>
                                </div>
                              </div>
                            </section>
                          </v-col>
                        </v-row>
                      </div>

                      <div v-if="newSection.image.length">
                        <div class="my-5">
                          <h2
                            class="d-block text-subtitle-1 font-weight-bold black--text my-4"
                            style="line-height: 1.5rem !important"
                          >
                            Gambar yang akan diupload
                          </h2>
                        </div>
                        <v-row class="mb-5">
                          <v-col
                            v-for="(gallery, index) in newSection.image"
                            :key="gallery.uuid"
                            cols="6"
                            md="3"
                            lg="3"
                            xl="3"
                          >
                            <v-card
                              class="overflow-hidden"
                              style="z-index: 0"
                            >
                              <v-card-title
                                class="pa-2 position-absolute"
                                style="right: 0; z-index: 10"
                              >
                                <v-spacer></v-spacer>
                                <v-menu
                                  offset-y
                                  nudge-bottom="5"
                                  nudge-left="60"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="secondary"
                                      v-bind="attrs"
                                      class="image-btn rounded-lg"
                                      v-on="on"
                                      @click="deleteImageTemp(index, key)"
                                    >
                                      <v-icon size="20">
                                        {{ icons.mdiTrashCanOutline }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                </v-menu>
                              </v-card-title>

                              <div>
                                <v-overlay
                                  absolute
                                  :opacity="opacity"
                                ></v-overlay>
                                <v-img
                                  :lazy-src="gallery.image"
                                  :src="gallery.image"
                                  height="200"
                                ></v-img>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>

                      <div v-if="isLoadingData">
                        <v-row class="mb-5">
                          <v-col
                            v-for="(i, index) in 8"
                            :key="index"
                            md="3"
                          >
                            <v-skeleton-loader
                              height="190"
                              type="card-avatar"
                            ></v-skeleton-loader>
                          </v-col>
                        </v-row>
                      </div>

                      <div
                        v-else
                        class="my-5"
                      >
                        <div>
                          <h2
                            class="d-block text-subtitle-1 font-weight-bold black--text mb-4"
                            style="line-height: 1.5rem !important"
                          >
                            Gambar yang sudah diupload
                          </h2>
                        </div>

                        <v-row>
                          <v-col
                            v-for="gallery in newSection.galleries"
                            :key="gallery.uuid"
                            cols="6"
                            md="3"
                            lg="3"
                            xl="3"
                          >
                            <v-card
                              class="overflow-hidden"
                              style="z-index: 0"
                            >
                              <v-card-title
                                class="pa-2 position-absolute"
                                style="right: 0; z-index: 10"
                              >
                                <v-spacer></v-spacer>
                                <v-menu
                                  offset-y
                                  nudge-bottom="5"
                                  nudge-left="60"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="secondary"
                                      v-bind="attrs"
                                      class="image-btn rounded-lg"
                                      v-on="on"
                                      @click="confirmDestroy(gallery.uuid, key)"
                                    >
                                      <v-icon size="20">
                                        {{ icons.mdiTrashCanOutline }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                </v-menu>
                              </v-card-title>

                              <div>
                                <v-overlay
                                  absolute
                                  :opacity="opacity"
                                ></v-overlay>
                                <v-img
                                  :lazy-src="gallery.image"
                                  :src="gallery.image"
                                  height="200"
                                ></v-img>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>

                      <div
                        v-if="newSection.galleries.length < 1"
                        class="d-flex justify-center"
                      >
                        <div class="pa-4 text-center">
                          <v-img
                            src="../../assets/images/vector/cbt-empty.svg"
                            class="mx-8 my-7"
                          ></v-img>
                          <p class="my-7">
                            Belum ada Galeri
                          </p>
                        </div>
                      </div>

                      <v-divider></v-divider>

                      <div class="mt-5">
                        <v-pagination
                          v-if="totalPages"
                          v-model="page"
                          :length="totalPages"
                          total-visible="8"
                          @change="paginationHandler"
                        ></v-pagination>
                      </div>
                    </v-col>

                    <v-col
                      v-if="newSection.type === 'pictwithdescription'"
                      cols="12"
                      class="py-0"
                    >
                      <template>
                        <v-card
                          v-for="(gallery, indexKey) in newSection.galleries"
                          :key="indexKey"
                          outlined
                          class="mb-4"
                        >
                          <v-card-text>
                            <div>
                              <FileInput
                                label="Gambar"
                                outlined
                                dense
                                :disabled="isLoadingButton"
                                :prependicon="icons.mdiImageMultipleOutline"
                                @input="getImage($event, key, indexKey)"
                              ></FileInput>
                            </div>

                            <v-row class="mb-4">
                              <v-col
                                cols="6"
                                md="3"
                                lg="3"
                                xl="3"
                              >
                                <v-card
                                  class="overflow-hidden"
                                  style="z-index: 0"
                                >
                                  <div>
                                    <v-overlay
                                      absolute
                                      :opacity="opacity"
                                    ></v-overlay>
                                    <v-img
                                      :lazy-src="gallery.image"
                                      :src="gallery.image"
                                      height="200"
                                    ></v-img>
                                  </div>
                                </v-card>
                              </v-col>
                            </v-row>

                            <div>
                              <v-text-field
                                v-model="gallery.text_image"
                                label="Deskripsi Informasi"
                                hide-details
                                :loading="isLoadingData"
                                :disabled="isLoadingData"
                                outlined
                                dense
                              ></v-text-field>
                            </div>
                          </v-card-text>

                          <v-card-actions class="pt-5 pb-7">
                            <div class="ml-auto mr-0">
                              <a
                                color="primary"
                                class="text-capitalize pa-0"
                                @click="confirmDestroyImageDesc(gallery.uuid, key, indexKey)"
                              >
                                Hapus Gambar
                              </a>
                            </div>
                          </v-card-actions>
                        </v-card>
                      </template>

                      <template v-if="newSection.image.length">
                        <v-card
                          v-for="(newImage, index) in newSection.image"
                          :key="index"
                          outlined
                          class="mb-4"
                        >
                          <v-card-text>
                            <div>
                              <FileInput
                                label="Gambar"
                                outlined
                                dense
                                :disabled="isLoadingButton"
                                :prependicon="icons.mdiImageMultipleOutline"
                                @input="getImage($event, key, index)"
                              ></FileInput>
                            </div>

                            <div>
                              <v-text-field
                                v-model="newImage.text_image"
                                label="Deskripsi Informasi"
                                hide-details
                                :loading="isLoadingData"
                                :disabled="isLoadingData"
                                outlined
                                dense
                              ></v-text-field>
                            </div>
                          </v-card-text>

                          <v-card-actions class="pt-5 pb-7">
                            <div class="ml-auto mr-0">
                              <a
                                color="primary"
                                class="text-capitalize pa-0"
                                @click="confirmDestroyImageDesc(newImage.uuid, key, index)"
                              >
                                Hapus Gambar
                              </a>
                            </div>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions class="pt-5 pb-7">
                  <div class="ml-auto mr-0">
                    <v-btn
                      v-if="(newSection.type === 'pictwithdescription')"
                      outlined
                      color="primary"
                      class="text-capitalize mr-3"
                      width="120"
                      small
                      @click="addNewImageSection(key)"
                    >
                      Tambah Gambar
                    </v-btn>
                    <v-btn
                      outlined
                      color="primary"
                      class="text-capitalize mr-3"
                      width="120"
                      small
                      @click="showEditSection(newSection.uuid, key)"
                    >
                      Edit Section
                    </v-btn>
                    <v-btn
                      outlined
                      color="primary"
                      class="text-capitalize"
                      width="120"
                      small
                      @click="popUpDelete(newSection.uuid, key)"
                    >
                      Hapus Section
                    </v-btn>
                  </div>
                </v-card-actions>
              </div>
            </v-card>
          </template>

          <div class="text-center">
            <v-btn
              color="primary"
              outlined
              large
              @click="showNewSection()"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon> Tambah Section
            </v-btn>
          </div>
          <v-divider class="mt-10"></v-divider>
        </v-card-text>

        <v-card-actions class="d-flex text-center justify-center align-center py-10">
          <div>
            <v-btn
              outlined
              color="primary"
              large
              class="me-2"
            >
              Batal
            </v-btn>
          </div>
          <div>
            <v-btn
              color="primary"
              large
              :loading="isLoadingButton"
              :disabled="isLoadingButton"
              @click="addLayout"
            >
              Simpan
            </v-btn>
          </div>
        </v-card-actions>
      </div>
    </v-card>

    <div v-else>
      <v-skeleton-loader
        v-for="i in 3"
        :key="i"
        class="mb-5"
        type="image,article, actions,list-item-avatar"
      >
      </v-skeleton-loader>
    </div>

    <v-dialog
      v-model="addFormNewSection"
      persistent
      width="1000"
    >
      <v-card>
        <v-card-title class="headline justify-center">
          Tambah Section
        </v-card-title>
        <v-icon
          style="position: absolute; top: 20px; right: 20px"
          @click="handleCloseDialog"
        >
          {{ icons.mdiClose }}
        </v-icon>
        <v-card-text class="pt-4 px-10">
          <v-row>
            <v-col cols="12 py-0">
              <div>
                <v-text-field
                  v-model="section.title"
                  outlined
                  dense
                  :loading="isLoadingSection"
                  :disabled="isLoadingSection"
                  label="Nama Section"
                ></v-text-field>
              </div>

              <div>
                <v-autocomplete
                  v-model="section.type"
                  :items="types"
                  :loading="isLoadingSection"
                  :disabled="isLoadingSection"
                  item-value="type"
                  item-text="title"
                  outlined
                  dense
                  label="Jenis Section"
                  @change="showResult(section.type)"
                ></v-autocomplete>
              </div>

              <div>
                <h3 class="m-0">
                  Contoh
                </h3>
                <div
                  v-if="showTextSection == true"
                  id="add-only-text"
                >
                  <h2 class="text-center font-weight-medium my-5">
                    Visi & Misi
                  </h2>
                  <p class="mb-8 black--text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, sit nisl non mauris egestas risus
                    placerat aliquam viverra. Nec, viverra proin morbi at mi. Elementum eget purus sem semper. Commodo
                    pharetra, blandit posuere in tincidunt. Sit curabitur sollicitudin tincidunt sodales molestie tellus
                    volutpat massa gravida. Arcu cras lorem placerat ac enim adipiscing eget aliquam condimentum.
                    Elementum semper id in pulvinar. In mauris volutpat fringilla nunc eget purus sed urna sagittis.
                    Consectetur sit volutpat turpis eros ipsum lectus. purus sed urna sagittis.Consectetur sit volutpat
                    turpis eros ipsum lectus.
                  </p>
                </div>

                <div
                  v-if="showImageAndTextSection == true"
                  id="add-text-and-pict"
                >
                  <h2 class="text-center font-weight-medium my-5">
                    Galeri
                  </h2>
                  <p class="mb-8 black--text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus, sit nisl non mauris egestas risus
                    placerat aliquam viverra. Nec, viverra proin morbi at mi. Elementum eget purus sem semper. Commodo
                    pharetra, blandit posuere in tincidunt. Sit curabitur sollicitudin tincidunt sodales molestie tellus
                    volutpat massa gravida.
                  </p>
                  <div class="mb-8">
                    <v-row>
                      <v-col cols="6">
                        <v-img src="@/assets/images/landingpage/1.png"></v-img>
                      </v-col>
                      <v-col cols="6">
                        <v-img src="@/assets/images/landingpage/2.png"></v-img>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <div
                  v-if="showImageSection == true"
                  id="add-only-pict"
                >
                  <h2 class="text-center font-weight-medium my-5">
                    Dokumentasi
                  </h2>
                  <div class="mb-8">
                    <v-row>
                      <v-col cols="6">
                        <v-img src="@/assets/images/landingpage/1.png"></v-img>
                      </v-col>
                      <v-col cols="6">
                        <v-img src="@/assets/images/landingpage/2.png"></v-img>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <div
                  v-if="showSliderSection == true"
                  id="add-slider"
                >
                  <h2 class="text-center font-weight-medium my-5">
                    Berita Terkini
                  </h2>
                  <div class="mb-8">
                    <v-row>
                      <v-col cols="12">
                        <template>
                          <v-carousel
                            height="300"
                            hide-delimiter-background
                            show-arrows-on-hover
                            class="rounded-lg"
                          >
                            <v-carousel-item
                              v-for="(item, index) in items"
                              :key="index"
                              :src="item.src"
                            >
                            </v-carousel-item>
                          </v-carousel>
                        </template>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <div
                  v-if="showImageAndDescription == true"
                  id="add-image-with-description"
                >
                  <h2 class="text-center font-weight-medium my-5">
                    Berita Terkini
                  </h2>
                  <div class="mb-8">
                    <v-row>
                      <v-col cols="6">
                        <v-img src="@/assets/images/landingpage/1.png"></v-img>
                        <h3 class="font-weight-medium my-4">
                          Proses Evakuasi Pasien Covid 19
                        </h3>
                      </v-col>
                      <v-col cols="6">
                        <v-img src="@/assets/images/landingpage/2.png"></v-img>
                        <h3 class="font-weight-medium my-4">
                          Proses Check Up Pasien
                        </h3>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            outlined
            large
            color="primary"
            @click="handleCloseDialog"
          >
            Batal
          </v-btn>
          <v-btn
            v-if="dialogSection === 'add'"
            large
            color="primary"
            :disabled="!section.title || !section.type"
            @click="addSection"
          >
            Tambah
          </v-btn>
          <v-btn
            v-if="dialogSection === 'edit'"
            large
            color="primary"
            @click="editSection"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog
      :visible="confirmDeleteSection"
      header="Hapus Section"
      :is-loading-button="isLoadingDelete"
      @close-button="confirmDeleteSection = false"
      @destroy-button="deleteSection"
    >
    </ConfirmDialog>

    <ConfirmDialog
      :visible="confirmDeleteImage"
      header="Hapus Foto"
      :is-loading-button="isLoadingDelete"
      @close-button="confirmDeleteImage = false"
      @destroy-button="deleteImage"
    >
    </ConfirmDialog>

    <ConfirmDialog
      :visible="confirmDeleteImageDesc"
      header="Hapus Foto"
      :is-loading-button="isLoadingDelete"
      @close-button="confirmDeleteImageDesc = false"
      @destroy-button="deleteImageDesc"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
// eslint-disable-next-line object-curly-newline
import { mdiArrowDownThin, mdiArrowUpThin, mdiClose, mdiCrop, mdiImageMultipleOutline, mdiInformation, mdiPlus, mdiRefresh, mdiTownHall, mdiTrashCanOutline } from '@mdi/js'
import 'cropperjs/dist/cropper.css'
import VueCropper from 'vue-cropperjs'
import FileInput from '../components/FileInput.vue'

export default {
  name: 'Instance',
  components: {
    FileInput,
    ConfirmDialog,
    VueCropper,
  },
  props: {},
  data() {
    return {
      icons: {
        mdiImageMultipleOutline,
        mdiTrashCanOutline,
        mdiTownHall,
        mdiCrop,
        mdiRefresh,
        mdiPlus,
        mdiClose,
        mdiInformation,
        mdiArrowUpThin,
        mdiArrowDownThin,
      },
      instance: {
        company_types_uuid: {
          type: '',
        },
      },
      confirmDeleteSection: false,
      confirmDeleteImage: false,
      confirmDeleteImageDesc: false,
      isLoadingInstance: false,
      serviceInstance: 'instance',
      instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
      page: 1,
      totalPages: 0,
      isLoadingData: false,
      isLoadingButton: false,
      opacity: 0.3,
      galleryUuid: null,
      galleryIndex: null,
      galleries: [],
      galleriesTemp: [],
      serviceGallery: 'gallery',
      informations: [],
      information: [],
      addFormNewSection: false,
      layoutUuid: null,
      items: [
        {
          src: 'https://images.pexels.com/photos/247786/pexels-photo-247786.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        },
        {
          src: 'https://images.pexels.com/photos/1170979/pexels-photo-1170979.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        },
        {
          src: 'https://images.pexels.com/photos/356040/pexels-photo-356040.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        },
        {
          src: 'https://images.pexels.com/photos/305568/pexels-photo-305568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
        },
      ],
      newSections: [
        {
          title: 'Informasi',
          subtitle: 'Hanya Teks',
          type: 'onlytext',
          description: '',
          image: [],
          galleries: [],
          default_index: 1,
        },
        {
          title: 'Visi Misi',
          subtitle: 'Hanya Teks',
          type: 'onlytext',
          description: '',
          image: [],
          galleries: [],
          default_index: 2,
        },
        {
          title: 'Informasi Kontak',
          subtitle: 'Hanya Teks',
          type: 'onlytext',
          description: '',
          image: [],
          galleries: [],
          default_index: 4,
        },
        {
          title: 'Galeri',
          subtitle: 'Hanya Gambar',
          type: 'onlypict',
          description: '',
          image: [],
          galleries: [],
          default_index: 5,
        },
        {
          title: 'Berita',
          subtitle: 'Gambar Slider',
          type: 'slider',
          description: '',
          image: [],
          galleries: [],
          default_index: 7,
        },
        {
          title: 'Gambar dan Deskripsi',
          subtitle: 'Gambar denga deskripsi',
          type: 'pictwithdescription',
          description: '',
          image: [
            {
              image: '',
              file: '',
              text_image: '',
            },
          ],
          galleries: [],
          default_index: 8,
        },
      ],
      // newImages: [
      //   {
      //     image: '',
      //     description: '',
      //   },
      // ],
      section: {
        title: '',
        type: '',
      },
      types: [
        {
          title: 'Hanya Teks',
          type: 'onlytext',
        },
        {
          title: 'Hanya Gambar',
          type: 'onlypict',
        },
        {
          title: 'Teks dan Gambar',
          type: 'textwithpict',
        },
        {
          title: 'Gambar Slider',
          type: 'slider',
        },
        {
          title: 'Gambar dan Deskripsi',
          type: 'pictwithdescription',
        },
      ],
      dialogSection: '',
      serviceLayout: 'layout',
      isLoadingSection: false,
      isLoadingDelete: false,
      showTextSection: false,
      showImageSection: false,
      showImageAndTextSection: false,
      showSliderSection: false,
      showImageAndDescription: false,
      indexSection: null,
      cropImg: '',
      imgSrc: '',
      loadingImage: false,
      uploadedImg: {},
      showImg: {},
      uploadedImgDesc: {},
      showImgDesc: {},
      moveInterval: null,
    }
  },

  mounted() {
    this.getDetailInstance()
  },
  methods: {
    async getDetailInstance() {
      this.isLoadingInstance = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          if (data.data.layouts.length) {
            this.newSections = []
            data.data.layouts.forEach((item, indexChild) => {
              this.newSections.push({ image: [], ...item })
            })
          }

          this.isLoadingInstance = false
        },
        err => {
          console.error(err)
          this.isLoadingInstance = false
        },
      )

      this.isLoadingInstance = false
    },

    addNewImageSection(key) {
      const data = {
        image: '',
        file: '',
        text_image: '',
      }

      this.newSections[key].image.push(data)
    },

    addSection() {
      let subtitle = ''

      switch (this.section.type) {
        case 'onlytext':
          subtitle = 'Hanya Teks'
          break
        case 'onlypict':
          subtitle = 'Hanya Gambar'
          break
        case 'textwithpict':
          subtitle = 'Gambar dan Teks'
          break
        case 'slider':
          subtitle = 'Slider'
          break
        case 'pictwithdescription':
          subtitle = 'Gambar dan Deskripsi'
          break
        default:
          break
      }

      if (this.section.type === 'pictwithdescription') {
        const data = {
          title: this.section.title,
          subtitle,
          type: this.section.type,
          description: '',
          image: [
            {
              image: '',
              file: '',
              text_image: '',
            },
          ],
          galleries: [],
        }
        this.newSections.push(data)
      } else {
        const data = {
          title: this.section.title,
          subtitle,
          type: this.section.type,
          description: '',
          image: [],
          galleries: [],
        }
        this.newSections.push(data)
      }

      this.addFormNewSection = false
    },

    editSection(index) {
      let subtitle = ''

      switch (this.section.type) {
        case 'onlytext':
          subtitle = 'Hanya Teks'
          break
        case 'onlypict':
          subtitle = 'Hanya Gambar'
          break
        case 'textwithpict':
          subtitle = 'Gambar dan Teks'
          break
        case 'slider':
          subtitle = 'Slider'
          break
        case 'pictwithdescription':
          subtitle = 'Gambar dan Deskripsi'
          break
        default:
          break
      }

      this.newSections[this.indexSection].title = this.section.title
      this.newSections[this.indexSection].type = this.section.type
      this.newSections[this.indexSection].subtitle = subtitle

      this.addFormNewSection = false
    },

    popUpDelete(uuid, index) {
      this.layoutUuid = uuid
      this.indexSection = index
      this.confirmDeleteSection = true
    },

    async deleteSection() {
      this.isLoadingDelete = true

      if (this.layoutUuid === undefined) {
        this.newSections.splice(this.indexSection, 1)
      } else {
        await this.$services.ApiServices.destroy(this.serviceLayout, this.layoutUuid).then(({ data }) => {
          this.showSnackbar({
            text: 'Layout berhasil dihapus',
            color: 'error',
          })
        })
      }

      this.isLoadingDelete = false
      this.getDetailInstance()
      this.confirmDeleteSection = false
    },

    showNewSection() {
      this.dialogSection = 'add'
      this.showTextSection = false
      this.showImageSection = false
      this.showImageAndTextSection = false
      this.showSliderSection = false
      this.showImageAndDescription = false
      this.section = {
        title: '',
        type: '',
      }
      this.addFormNewSection = true
    },

    async showEditSection(uuid, key) {
      this.dialogSection = 'edit'
      this.indexSection = key
      this.addFormNewSection = true
      this.isLoadingSection = true

      if (!uuid) {
        this.section = this.newSections[this.indexSection]
      } else {
        await this.$services.ApiServices.get(this.serviceLayout, uuid).then(
          ({ data }) => {
            this.section = data.data
            this.isLoadingSection = false
          },
          err => {
            console.error(err)
            this.isLoadingSection = false
          },
        )
      }

      this.showResult(this.section.type)
      this.isLoadingSection = false
    },

    handleCloseDialog() {
      this.addFormNewSection = false
    },

    showResult(type) {
      this.section.type = type

      if (type === 'onlytext') {
        this.showTextSection = true
        this.showImageSection = false
        this.showImageAndTextSection = false
        this.showSliderSection = false
        this.showImageAndDescription = false
      } else if (type === 'onlypict') {
        this.showImageSection = true
        this.showTextSection = false
        this.showImageAndTextSection = false
        this.showSliderSection = false
        this.showImageAndDescription = false
      } else if (type === 'textwithpict') {
        this.showImageAndTextSection = true
        this.showImageSection = false
        this.showTextSection = false
        this.showImageAndDescription = false
        this.showSliderSection = false
      } else if (type === 'slider') {
        this.showSliderSection = true
        this.showImageSection = false
        this.showImageAndTextSection = false
        this.showTextSection = false
        this.showImageAndDescription = false
      } else if (type === 'pictwithdescription') {
        this.showImageAndDescription = true
        this.showTextSection = false
        this.showImageSection = false
        this.showImageAndTextSection = false
        this.showSliderSection = false
      } else {
        this.showTextSection = false
        this.showImageSection = false
        this.showImageAndTextSection = false
        this.showSliderSection = false
        this.showImageAndDescription = false
      }
    },

    async addLayout() {
      this.isLoadingButton = true

      this.formData = new FormData()
      this.newSections.forEach((item, indexParent) => {
        if (item.uuid !== undefined) {
          this.formData.append('uuid[]', item.uuid)
        }
        this.formData.append('title[]', item.title)
        this.formData.append('company_uuid[]', this.instanceUuid)
        this.formData.append('subtitle[]', item.subtitle)
        this.formData.append('type_section[]', item.type)
        this.formData.append('description[]', item.description)

        if (item.image.length > 0) {
          item.image.forEach((item, indexChild) => {
            this.formData.append(`image[${indexParent}][${indexChild}]`, item.file)
            this.formData.append(`text_image[${indexParent}][${indexChild}]`, item.text_image)
          })
        }
        if (item.galleries.length > 0) {
          item.galleries.forEach((item, indexChild) => {
            if (typeof item.file !== 'undefined') {
              this.formData.append(`image[${indexParent}][${indexChild}]`, item.file)
            }
            if (!this.formData.get(`text_image[${indexParent}][${indexChild}]`)) { this.formData.append(`text_image[${indexParent}][${indexChild}]`, item.text_image) }
          })
        }
      })

      await this.$services.ApiServices.add(this.serviceLayout, this.formData).then(
        ({ data }) => {
          this.isLoadingButton = false

          this.showSnackbar({
            text: 'Berhasil Menambah Section Landing Page',
            color: 'success',
          })
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      this.isLoadingButton = false
    },

    async paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    getImage(event, key, index) {
      this.newSections[key].image[index].file = event
      this.newSections[key].image[index].image = URL.createObjectURL(event)
    },

    move(key, offsetX, offsetY) {
      this.moveInterval = setInterval(() => {
        const cropper = this.$refs[`cropper${key}`]
        cropper[0].move(offsetX, offsetY)
      }, 20)
    },

    stopMove() {
      clearInterval(this.moveInterval)
    },

    cropImage(key) {
      if (this.newSections[key].image.length > 3) {
        this.showSnackbar({
          text: 'Maksimal 4 Photo setiap upload',
          color: 'error',
        })
      } else {
        const cropper = this.$refs[`cropper${key}`]
        cropper[0].getCroppedCanvas().toBlob(blob => {
          const image = {
            image: URL.createObjectURL(blob),
            file: blob,
          }
          this.newSections[key].image.unshift(image)
        }, this.mime_type)
      }
    },

    reset(key) {
      const cropper = this.$refs[`cropper${key}`]
      cropper[0].reset()
    },

    getFile(event, key) {
      this.uploadedImg[key] = event

      if (this.uploadedImg[key] !== null) {
        this.showImg[key] = key
      } else {
        this.showImg[key] = {}
      }

      if (event.size > 2100000) {
        this.showSnackbar({
          text: 'Maksimal size Photo hanya 2MB',
          color: 'error',
        })

        this.uploadedImg[key] = {}

        return
      }

      if (this.newSections[key].image.length === 4) {
        this.showSnackbar({
          text: 'Maksimal 4 Photo setiap upload',
          color: 'error',
        })

        return
      }

      const file = event
      if (file.type.indexOf('image/') === -1) {
        this.showSnackbar({
          text: 'Silahkan tambahkan satu file gambar',
          color: 'error',
        })

        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          const cropper = this.$refs[`cropper${key}`]
          cropper[0].replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        this.showSnackbar({
          text: 'Maaf, FileReader API tidak didukung',
          color: 'error',
        })
      }
    },

    deleteImageTemp(index, key) {
      this.newSections[key].image.splice(index, 1)
    },

    confirmDestroy(uuid, key) {
      this.confirmDeleteImage = true
      this.galleryUuid = uuid
      this.indexSection = key
    },

    async deleteImage() {
      this.isLoadingDelete = true

      await this.$services.ApiServices.destroy(this.serviceGallery, this.galleryUuid).then(({ data }) => {
        this.showSnackbar({
          text: 'Foto berhasil dihapus',
          color: 'error',
        })
        this.getDetailInstance()
      })

      this.isLoadingDelete = false
      this.confirmDeleteImage = false
    },

    confirmDestroyImageDesc(uuid, key, index) {
      this.confirmDeleteImageDesc = true
      this.galleryUuid = uuid
      this.indexSection = key
      this.galleryIndex = index
    },

    async deleteImageDesc() {
      this.isLoadingDelete = true

      if (this.galleryUuid === undefined) {
        this.newSections[this.indexSection].image.splice(this.galleryIndex, 1)
        this.isLoadingDelete = false
        this.confirmDeleteImageDesc = false
      }

      await this.$services.ApiServices.destroy(this.serviceGallery, this.galleryUuid).then(({ data }) => {
        this.showSnackbar({
          text: 'Foto berhasil dihapus',
          color: 'error',
        })
        this.getDetailInstance()
      })

      this.isLoadingDelete = false
      this.confirmDeleteImageDesc = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    resetForm() {
      this.galleriesTemp = []
    },
  },
}
</script>

<style>
.alert > .v-alert__wrapper > .v-alert__content {
  display: flex;
  align-items: center;
}

.image-btn {
  height: 38px !important;
  width: 38px !important;
  min-width: 0 !important;
  padding: 0 !important;
}

.image-btn.secondary {
  background-color: #00000047 !important;
}

.btn-reset,
.btn-crop {
  width: 155px;
}

.btn-tools {
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  min-width: 0 !important;
  padding: 0 !important;
}
</style>
