import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/library/company'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  get(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${uuid}${paramsString}`)
  },
  update(news, uuid) {
    return http.post(`${resource}/${uuid}?_method=PATCH`, news)
  },
  add(news) {
    return http.post(`${resource}`, news)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
