const library = [
  {
    path: '/library',
    name: 'library',
    component: () => import('@/views/library/Library.vue'),
    meta: {
      layout: 'content',
      resource: 'LibraryInstance',
      action: 'read',
    },
  },
]
export default library
