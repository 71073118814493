const apps = [
  {
    path: '/user/:uuid/profile',
    name: 'apps-user-view',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AppUserView',
    },
  },
  {
    path: '/apps/faq',
    name: 'apps-faq',
    component: () => import('@/views/apps/faq/Faq.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AppUserFaq',
    },
  },
]

export default apps
