<template>
  <div>
    <v-row class="match-height mb-4">
      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-text class="greeting-title">
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <div class="py-5">
                  <p class="text-no-wrap text-lg mb-1">
                    Selamat Datang di
                  </p>
                  <p class="text--primary mb-0 font-weight-bold text-xl">
                    Dashboard Superadmin!
                    <span>🎉</span>
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <div>
                  <v-img
                    width="20"
                    src="@/assets/images/misc/tree-3.svg"
                    class="gamification-tree-3"
                  ></v-img>
                  <v-img
                    width="90"
                    src="@/assets/images/3d-characters/pose-2.svg"
                    class="gamification-john-pose-2"
                  ></v-img>
                  <v-img
                    width="15"
                    src="@/assets/images/misc/tree.svg"
                    class="gamification-tree"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card>
          <v-card-title>
            <span>Library</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="library in libraries"
                :key="library.title"
                md="4"
              >
                <v-row class="align-center">
                  <v-col md="4">
                    <v-avatar
                      :color="library.color"
                      size="40"
                    >
                      <v-container class="d-flex align-center">
                        <v-img :src="library.icon"></v-img>
                      </v-container>
                    </v-avatar>
                  </v-col>
                  <v-col md="8">
                    <span class="d-block">{{ library.title }}</span>
                    <v-progress-circular
                      v-if="loadingLib"
                      indeterminate
                      :color="library.color"
                    ></v-progress-circular>
                    <div
                      v-else
                      class="d-flex align-center flex wrap"
                    >
                      <p class="font-weight-bold text--black mb-0 me-1 text-h6">
                        {{ library.total }}
                      </p>
                      <p
                        class="text-xs percentage mb-0"
                        :class="library.ratio < 0 ? 'error--text' : 'success--text'"
                      >
                        {{ library.ratio < 0 ? '-' : '+' }}{{ library.ratio }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <p class="mb-0 text-lg font-weight-bold">
          Instansi Pengguna Titik Baca
        </p>
      </v-col>
      <v-col
        v-for="menu in menus"
        :key="menu.title"
        xl="2"
        lg="2"
        md="2"
        cols="6"
      >
        <v-card>
          <v-card-title>
            <v-avatar :color="menu.color">
              <v-container class="d-flex align-center">
                <v-img
                  :src="menu.icon"
                ></v-img>
              </v-container>
            </v-avatar>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p class="text-sm font-weight-bold">
              {{ menu.title }}
            </p>
            <div class="d-flex align-center flex wrap">
              <v-progress-circular
                v-if="loadingCircle"
                indeterminate
                :color="menu.color"
              ></v-progress-circular>
              <div
                v-else
                class="d-flex align-center flex wrap"
              >
                <p class="font-weight-bold text--black mb-0 me-1 text-h6">
                  {{ menu.total }}
                </p>
                <p
                  class="text-xs percentage mb-0"
                  :class="menu.ratio < 0 ? 'error--text' : 'success--text'"
                >
                  {{ menu.ratio < 0 ? '' : '+' }}{{ menu.ratio }}
                </p>
              </div>
            </div>
            <span>Total {{ menu.title }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        xl="8"
        lg="8"
        md="12"
        sm="12"
        cols="12"
        class="mt-2"
      >
        <v-card>
          <v-card-title>
            <span class="d-block mb-4">Aktifitas Penggunaan Aplikasi</span>
            <v-spacer></v-spacer>
            <div>
              <v-autocomplete
                label="2022"
                outlined
                dense
                :items="yearActivities"
                class="select-year me-1"
              ></v-autocomplete>
            </div>
            <div>
              <v-btn
                icon
                small
                class="me-n3 mt-n2"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <vue-apex-charts
              id="total-profit-chart"
              height="320"
              :options="chartOptions"
              :series="chartData"
            ></vue-apex-charts>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        xl="4"
        lg="4"
        md="12"
        sm="12"
        cols="12"
        class="mt-2"
      >
        <v-card>
          <v-card-title class="align-center">
            <span>Instansi Baru</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-between align-center">
              <p class="d-block my-2 font-weight-bold text-uppercase text-xs">
                Nama Instansi
              </p>
              <p class="d-block my-2 font-weight-bold text-uppercase text-xs">
                Tanggal Dibuat
              </p>
            </div>
            <div v-show="isShow">
              <div
                v-if="loadingCircle"
                class="text-center py-4"
              >
                <v-progress-circular
                  indeterminate
                  :color="randomColor()"
                ></v-progress-circular>
              </div>
              <div
                v-for="instance in instances.slice(0, 6)"
                v-else
                :key="instance.uuid"
                class="d-flex justify-space-between align-center mt-3 mb-5"
              >
                <div class="d-flex justify-start align-center">
                  <div>
                    <v-avatar
                      :color="randomColor()"
                      class="v-avatar-light-bg me-2"
                      :class="randomTextColor()"
                      size="40"
                    >
                      <span>{{ instance.name.substring(0,3) }}</span>
                    </v-avatar>
                  </div>
                  <p class="mb-0 font-weight-medium black--text">
                    {{ instance.name }}
                  </p>
                </div>
                <p class="mb-0">
                  {{ dateFormat(instance.created_at) }}
                </p>
              </div>
            </div>
            <div v-show="isShowAll">
              <div
                v-if="loadingCircle"
                class="text-center py-4"
              >
                <v-progress-circular
                  indeterminate
                  :color="randomColor()"
                ></v-progress-circular>
              </div>
              <div
                v-for="instance in instances"
                v-else
                :key="instance.uuid"
                class="d-flex justify-space-between align-center mt-3 mb-5"
              >
                <div class="d-flex justify-start align-center">
                  <div>
                    <v-avatar
                      :color="randomColor()"
                      class="v-avatar-light-bg me-2"
                      :class="randomTextColor()"
                      size="40"
                    >
                      <span>{{ instance.name.substring(0,3) }}</span>
                    </v-avatar>
                  </div>
                  <p class="mb-0 font-weight-medium black--text">
                    {{ instance.name }}
                  </p>
                </div>
                <p class="mb-0">
                  {{ dateFormat(instance.created_at) }}
                </p>
              </div>
            </div>
            <div>
              <div
                v-if="instances.length > 6"
                v-show="isHideButton"
                class="text-center my-2"
              >
                <a
                  class="d-block primary--text"
                  @click="showAll()"
                >Lihat Semua</a>
              </div>
              <div
                v-show="isShowButton"
                class="text-center my-2"
              >
                <a
                  class="d-block primary--text"
                  @click="moreLess()"
                >Lebih Sedikit</a>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import iconAirport from '@/assets/icons/white/airport.svg'
import iconGasStation from '@/assets/icons/white/gas-station.svg'
import iconHospital from '@/assets/icons/white/hospital.svg'
import iconInstance from '@/assets/icons/white/instance.svg'
import iconLitepad from '@/assets/icons/white/litepad.svg'
import iconPlay from '@/assets/icons/white/play.svg'
import iconSchool from '@/assets/icons/white/school.svg'
import iconTrain from '@/assets/icons/white/train.svg'
import iconUsers from '@/assets/icons/white/users.svg'
import dateTimeFormat from '@/utils/date/dateTimeFormat'
import { kFormatter } from '@core/utils/filter'
import { mdiDotsVertical } from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'DashboardAdmin',
  components: {
    VueApexCharts,
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        iconPlay,
        iconUsers,
        iconLitepad,
        iconSchool,
        iconAirport,
        iconGasStation,
        iconHospital,
        iconTrain,
        iconInstance,
      },
      isShow: true,
      isShowAll: false,
      isHideButton: true,
      isShowButton: false,
      libraries: [
        {
          icon: iconPlay,
          title: 'E-Book',
          total: 0,
          ratio: 0,
          color: '#00BB8D',
          keyName: 'total_ebook',
          keyRatioName: 'ratio_ebook',
        },
        {
          icon: iconLitepad,
          title: 'Audio Book',
          total: 0,
          ratio: 0,
          color: '#5680CB',
          keyName: 'total_audio_book',
          keyRatioName: 'ratio_audio',
        },
        {
          icon: iconUsers,
          title: 'Video Book',
          total: 0,
          ratio: 0,
          color: '#56CA00',
          keyName: 'total_video_book',
          keyRatioName: 'ratio_video',
        },
      ],
      menus: [
        {
          icon: iconSchool,
          title: 'Sekolah',
          total: 0,
          ratio: 12,
          color: '#105BBC',
          keyName: 'school_count',
          keyNameRatio: 'school_ratio',
        },
        {
          icon: iconAirport,
          title: 'Bandara',
          total: 0,
          ratio: 0,
          color: '#FF9E00',
          keyName: 'airport_count',
          keyNameRatio: 'airport_ratio',
        },
        {
          icon: iconGasStation,
          title: 'SPBU',
          total: 0,
          ratio: 0,
          color: '#16B9FF',
          keyName: 'gas_station_count',
          keyNameRatio: 'gas_station_ratio',
        },
        {
          icon: iconHospital,
          title: 'Rumah Sakit',
          total: 0,
          ratio: 0,
          color: '#FFC361',
          keyName: 'hospital_count',
          keyNameRatio: 'hospital_ratio',
        },
        {
          icon: iconTrain,
          title: 'Stasiun',
          total: 0,
          ratio: 0,
          color: '#FF5F00',
          keyName: 'station_count',
          keyNameRatio: 'station_ratio',
        },
        {
          icon: iconInstance,
          title: 'Instansi Lain',
          total: 0,
          ratio: 0,
          color: '#00BB8D',
          keyName: 'other_count',
          keyNameRatio: 'other_ratio',
        },
      ],
      instances: [],
      service: 'dashboardadmin',
      serviceLibrary: 'library',

      loadingCircle: true,
      loadingLib: true,
      yearActivities: [],
      chartOptions: {
        colors: ['#00BB8D'],
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '35%',
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter: value => kFormatter(value, 0),
          },
        },
        grid: {
          strokeDashArray: 10,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          curve: 'smooth',
          width: 6,
          lineCap: 'round',
          colors: ['#fff'],
        },
        responsive: [
          {
            breakpoint: 1400,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '50%',
                },
              },
            },
          },
        ],
      },
      chartData: [
        {
          data: [],
        },
      ],
      customChartColor: '#3b3559',
    }
  },
  created() {
    this.getDataCount()
    this.getDataLibraries()
  },
  methods: {
    showAll() {
      this.isShow = false
      this.isShowAll = true
      this.isHideButton = false
      this.isShowButton = true
    },
    moreLess() {
      this.isHideButton = true
      this.isShowButton = false
      this.isShow = true
      this.isShowAll = false
    },
    dateFormat(date) {
      return dateTimeFormat(date, '/', false)
    },
    randomColor() {
      const color = ['warning', 'primary', 'success', 'error']

      const random = Math.floor(Math.random() * color.length)

      return color[random]
    },
    randomTextColor() {
      const colorText = ['warning--text', 'primary--text', 'success--text', 'error--text']

      const random = Math.floor(Math.random() * colorText.length)

      return colorText[random]
    },
    async getDataLibraries(params) {
      this.loadingLib = true
      await this.$services.ApiServices.list(this.serviceLibrary, { ...params, per_page: 1 }).then(({ data }) => {
        Object.keys(data).forEach((key, item) => {
          this.libraries.forEach(el => {
            if (el.keyName === key) {
              el.total = data[key]
            }
            if (el.keyRatioName === key) {
              el.ratio = data[key]
            }
          })
        })
      })
      this.loadingLib = false
    },
    async getDataCount(params) {
      this.loadingCircle = true
      await this.$services.ApiServices.list(this.service, { ...params }).then(({ data }) => {
        this.instances = data.latest_company
        // data.data.user_activities.forEach(el => {
        //   this.chartData[0].data.push(el.total_activity)
        //   this.chartOptions.xaxis.categories.push(el.month)
        //   this.yearActivities.push(el.year)
        // })
        // this.total = { ...this.dashboard }
        const chartData = [
          {
            data: [],
          },
        ]
        chartData[0].data = data.user_activities.map(el => ({ x: el.month, y: el.total_activity }))
        this.chartData = chartData
        Object.keys(data).forEach((key, item) => {
          this.menus.forEach(el => {
            if (el.keyName === key) {
              el.total = data[key]
            }
            if (el.keyNameRatio === key) {
              el.ratio = data[key]
            }
          })
        })
      })
      this.loadingCircle = false
    },
  },
}
</script>

<style>
.card-info,
.card-img {
  z-index: 1;
}
.card-info .font-weight,
.card-info .font-light {
  color: #fff;
}
.chartdiv {
  width: 100%;
  height: 320px;
}
.gamification-tree-3,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 5%;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 10%;
}
.gamification-tree-3 {
  bottom: 15%;
  right: 30%;
}
.match-height > v-col {
  display: flex;
  flex-flow: column;
}
.match-height > v-col > v-card {
  flex: 1 1 auto;
}
.select-year > .v-input__control > .v-text-field__details {
  display: none !important;
}
</style>
