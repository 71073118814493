<template>
  <div class="instance-data">
    <v-row class="py-5">
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="instance.identity"
            label="ID Instansi"
            dense
            :loading="!instance.identity.length && dialog === 'edit' || isLoadingId"
            disabled
            type="text"
            outlined
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="instance.name"
            label="Nama Instansi"
            dense
            :loading="!instance.name.length && dialog === 'edit'"
            :disabled="!instance.name.length && dialog === 'edit'"
            type="text"
            outlined
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="instance.company_types_uuid"
            label="Tipe Instansi"
            dense
            :items="types"
            :disabled="!types.length"
            :loading="!types.length"
            item-text="type"
            item-value="uuid"
            outlined
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="instance.email"
            label="Email Instansi"
            dense
            :loading="!instance.email.length && dialog === 'edit'"
            :disabled="!instance.email.length && dialog === 'edit'"
            type="email"
            outlined
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div>
          <h4 class="mb-4 mt-5">
            Alamat
          </h4>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="instance.province_uuid"
            label="Provinsi"
            item-text="name"
            item-value="uuid"
            dense
            :items="provinces"
            outlined
            :disabled="!provinces.length"
            @change="changeListRegencies()"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="instance.regencies_uuid"
            label="Kabupaten/Kota"
            item-text="name"
            item-value="uuid"
            :items="regenciesByProvince"
            :loading="loadingRegencies"
            dense
            outlined
            :disabled="!regenciesByProvince.length"
            @change="changeListDistrict()"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="instance.district_uuid"
            :items="districtsByRegencies"
            :loading="loadingDistrict"
            label="Kecamatan"
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :disabled="!districtsByRegencies.length"
            @change="changeListVillage()"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-model="instance.village_uuid"
            :items="villagesByDistricts"
            :loading="loadingVillages"
            label="Desa"
            item-text="name"
            item-value="uuid"
            dense
            outlined
            :disabled="!villagesByDistricts.length"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div>
          <h4 class="mb-4 mt-5">
            Informasi Login
          </h4>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-model="instance.username"
            label="Username"
            dense
            :loading="isLoadingEdit && dialog === 'edit'"
            :disabled="dialog === 'edit'"
            type="text"
            outlined
            :hint="hint"
            persistent-hint
            @change="checkUsername($event)"
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        cols="12"
        class="py-0"
      >
        <div>
          <v-text-field
            v-if="dialog === 'add'"
            v-model="instance.password"
            :append-icon="show ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
            :type="show ? 'text' : 'password'"
            label="Password"
            dense
            outlined
            @click:append="show = !show"
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        v-if="dialog === 'add'"
        cols="12"
        class="py-0"
      >
        <div>
          <h4 class="mb-4 mt-5">
            Pilih Paket Buku
          </h4>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <div>
          <v-autocomplete
            v-if="dialog === 'add'"
            v-model="instance.package_uuid"
            label="Paket Buku"
            :items="packages"
            item-text="name"
            :disabled="!packages.length"
            :loading="loadingPackage"
            item-value="uuid"
            :error-messages="!packages.length ? 'Paket tidak ditemukan' : ''"
            dense
            outlined
          >
          </v-autocomplete>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-center mb-1 mt-7">
      <v-btn
        color="primary"
        outlined
        class="mx-2"
        large
        @click="close"
      >
        Batal
      </v-btn>
      <v-btn
        color="primary"
        class="mx-2"
        large
        :disabled="!formValid || error"
        @click="add"
      >
        Selanjutnya
      </v-btn>
    </div>
  </div>
</template>

<script>
import randomString from '@/utils/random-string/randomString'
import { mdiEyeOffOutline, mdiEyeOutline, mdiImageMultipleOutline } from '@mdi/js'

export default {
  props: {
    instanceUuid: {
      type: String,
      default: () => '',
    },
    dialog: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      icons: {
        mdiImageMultipleOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      show: false,
      loadingRegencies: false,
      loadingDistrict: false,
      loadingVillages: false,
      loadingPackage: false,
      addFormDialog: false,
      cardActive: false,
      formValid: false,
      identityId: null,
      isLoadingId: false,
      isLoadingEdit: false,
      instance: {
        identity: '',
        name: '',
        company_types_uuid: null,
        email: '',
        province_uuid: null,
        regencies_uuid: null,
        district_uuid: null,
        village_uuid: null,
        username: '',
        password: '',
        package_uuid: null,
      },
      types: [],
      packages: [],
      serviceType: 'instancesetting',
      servicePackage: 'package',
      serviceInstance: 'instance',
      error: false,
      hint: '',
      provinces: [],
      regenciesByProvince: [],
      districtsByRegencies: [],
      villagesByDistricts: [],
    }
  },
  watch: {
    instance: {
      handler() {
        const valid = []
        let requiredField = []
        if (this.dialog === 'add') {
          requiredField = [
            'name',
            'company_types_uuid',
            'username',
            'password',
            'email',
            'package_uuid',
            'province_uuid',
            'regencies_uuid',
            'district_uuid',
          ]
        } else {
          requiredField = ['name', 'username', 'email']
        }

        Object.entries(this.instance).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
        this.$emit('component-valid', this.formValid)
      },
      deep: true,
    },
    instanceUuid: {
      async handler() {
        this.resetForm()
        if (this.instanceUuid) {
          await this.getInstance()
          await this.changeListRegencies()
          await this.changeListDistrict()
          await this.changeListVillage()
        }
      },
      deep: true,
    },
    dialog: {
      handler() {
        if (this.dialog === 'add') {
          this.hint = ''
          this.resetForm()
          this.checkId()
          this.handleChangeName()
        }
      },
      deep: true,
    },
  },
  created() {
    this.handleChangeName()
    this.checkId()
    this.listType()
    this.listPackage()
    this.listProvince()
  },
  async mounted() {
    if (this.instanceUuid) {
      await this.getInstance()
      await this.changeListRegencies()
      await this.changeListDistrict()
      await this.changeListVillage()
    }
  },
  methods: {
    async checkId() {
      this.isLoadingId = true
      await this.$services.ApiServices.checkId(this.serviceInstance).then(
        ({ data }) => {
          this.instance.identity = `TBD-${data + 1}`
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingId = false
    },
    async checkUsername(name) {
      await this.$services.AuthServices.checkUsername({ username: name }).then(
        ({ data }) => {
          this.error = false
          this.hint = data.result.message
        },
        err => {
          this.error = true
          this.hint = err.message
          console.error(err)
        },
      )
    },
    handleChangeName() {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        const randomUsername = randomString('TBD-')
        this.instance.username = randomUsername
        this.checkUsername(randomUsername)
      }, 500)
    },
    async getInstance() {
      this.isLoadingEdit = true
      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.instance.username = data.data.user.username
          this.instance.province_uuid = data.data.profile?.province?.uuid
          this.instance.regencies_uuid = data.data.profile?.regencies?.uuid
          this.instance.district_uuid = data.data.profile?.district?.uuid
          this.instance.village_uuid = data.data.profile?.village?.uuid
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingEdit = false
    },
    async listProvince() {
      await this.$services.ApiServices.list('province', { per_page: 'all' }).then(
        ({ data }) => {
          this.provinces = data.data
        },
        err => console.error(err),
      )
    },
    async listType(params = {}) {
      await this.$services.ApiServices.list(this.serviceType, {
        ...params,
        per_page: 'all',
      }).then(
        ({ data }) => {
          this.types = data.data
        },
        err => {
          console.error(err)
        },
      )
    },
    async listPackage(params = {}) {
      this.loadingPackage = true
      await this.$services.ApiServices.list(this.servicePackage, {
        ...params,
        per_page: 'all',
      }).then(
        ({ data }) => {
          this.packages = data.data
        },
        err => {
          console.error(err)
        },
      )
      this.loadingPackage = false
    },
    async changeListRegencies() {
      this.loadingRegencies = true
      await this.$services.ApiServices.list('regency', {
        per_page: 'all',
        province_uuid: this.instance.province_uuid,
      }).then(
        ({ data }) => {
          this.regenciesByProvince = data.data
        },
        err => console.error(err),
      )
      this.loadingRegencies = false
    },
    async changeListDistrict() {
      this.loadingDistrict = true
      await this.$services.ApiServices.list('district', {
        per_page: 'all',
        regencies_uuid: this.instance.regencies_uuid,
      }).then(
        ({ data }) => {
          this.districtsByRegencies = data.data
        },
        err => console.error(err),
      )
      this.loadingDistrict = false
    },
    async changeListVillage() {
      this.loadingVillages = true
      await this.$services.ApiServices.list('village', {
        per_page: 'all',
        district_uuid: this.instance.district_uuid,
      }).then(
        ({ data }) => {
          this.villagesByDistricts = data.data
        },
        err => console.error(err),
      )
      this.loadingVillages = false
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    add() {
      this.$emit('next-tab', this.instance)
    },
    close() {
      this.resetForm()
      this.$emit('close-dialog')
    },
    resetForm() {
      this.instance = {
        identity: '',
        name: '',
        company_types_uuid: null,
        email: '',
        province_uuid: null,
        regencies_uuid: null,
        district_uuid: null,
        village_uuid: null,
        username: '',
        password: '',
        package_uuid: null,
      }
    },
  },
}
</script>

<style>
</style>
