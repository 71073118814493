const instance = [
  {
    path: '/instance',
    name: 'instance',
    component: () => import('@/views/instance/Instance.vue'),
    meta: {
      layout: 'content',
      resource: 'InstanceAdmin',
      action: 'read',
    },
  },
]
export default instance
