<template>
  <div>
    <div class="d-flex justify-start align-center mb-8">
      <div>
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text mr-2"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <div v-if="isLoadingInstance">
          <v-progress-circular
            color="primary"
            :size="30"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <span class="d-block text-caption text-capitalize black--text">{{ instance.company_types_uuid.type }}</span>
          <span
            class="d-block text-subtitle-1 font-weight-bold black--text"
            style="line-height: 1.5rem !important"
          >
            {{ instance.name }}
          </span>
        </div>
      </div>
    </div>

    <v-row class="match-height mb-4">
      <v-col
        cols="12"
        md="8"
      >
        <v-card>
          <v-card-title>
            <span>Library</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="library in libraries"
                :key="library.title"
                md="4"
              >
                <v-row class="align-center">
                  <v-col md="4">
                    <v-avatar
                      :color="library.color"
                      size="50"
                    >
                      <v-container class="d-flex align-center">
                        <v-img :src="library.icon"></v-img>
                      </v-container>
                    </v-avatar>
                  </v-col>

                  <v-col md="8">
                    <span class="d-block">{{ library.title }}</span>
                    <v-progress-circular
                      v-if="loadingCircle"
                      class="d-flex align-center flex wrap"
                      indeterminate
                      :color="library.color"
                    ></v-progress-circular>
                    <div
                      v-else
                      class="d-flex align-center flex wrap"
                    >
                      <p class="font-weight-bold text--black mb-0 me-1 text-h6">
                        {{ library.total }}
                      </p>
                      <p
                        class="text-xs percentage mb-0"
                        :class="library.ratio < 0 ? 'error--text' : 'success--text'"
                      >
                        {{ library.ratio < 0 ? '' : '+' }}{{ library.ratio }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card>
          <v-card-title>
            <span>Pengunjung</span>
          </v-card-title>
          <v-card-text class="greeting-title">
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-progress-circular
                  v-if="loadingCircle"
                  class="d-flex align-center flex wrap"
                  indeterminate
                  color="success"
                ></v-progress-circular>
                <div
                  v-else
                  class="d-flex align-center flex wrap"
                >
                  <p class="font-weight-bold text--black mb-0 me-1 text-h6">
                    {{ instance.total_visitors_month }}
                  </p>
                  <p
                    class="text-xs percentage mb-0"
                    :class="instance.total_ratio_visitors_month < 0 ? 'error--text' : 'success--text'"
                  >
                    {{ instance.total_ratio_visitors_month }} %
                  </p>
                </div>
                <v-chip
                  class="v-chip-light-bg info--text mt-3"
                  color="info"
                >
                  Pengunjung Bulanan
                </v-chip>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <div>
                  <v-img
                    width="90"
                    src="@/assets/images/3d-characters/pose-m-11.svg"
                    class="gamification-john-pose-2"
                  ></v-img>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        xl="7"
        lg="7"
        md="12"
        sm="12"
        cols="12"
        class="mt-2"
      >
        <v-card>
          <v-card-title>
            <span class="d-block mb-4">Aktifitas Pengunjung</span>
            <v-spacer></v-spacer>
            <div>
              <v-autocomplete
                label="2022"
                outlined
                dense
                class="select-year me-1"
              ></v-autocomplete>
            </div>
            <div>
              <v-btn
                icon
                small
                class="me-n3 mt-n2"
              >
                <v-icon>
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <vue-apex-charts
              id="total-profit-chart"
              height="320"
              :options="chartOptions"
              :series="chartData"
            ></vue-apex-charts>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        xl="5"
        lg="5"
        md="12"
        sm="12"
        cols="12"
        class="mt-2"
      >
        <v-card>
          <v-card-title class="align-center">
            <span>Lokasi</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div
              v-if="loadingCircle"
              class="text-center py-4"
            >
              <v-progress-circular

                indeterminate
                size="40"
                color="primary"
              ></v-progress-circular>
            </div>

            <iframe
              v-else
              :src="url"
              width="100%"
              height="400"
              style="border:0;"
              allowfullscreen
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import iconBook from '@/assets/icons/white/book.svg'
import iconMusic from '@/assets/icons/white/music.svg'
import iconPlay from '@/assets/icons/white/play.svg'
import { kFormatter } from '@core/utils/filter'
import { mdiDotsVertical, mdiTownHall } from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'DashboardInstance',
  components: {
    VueApexCharts,
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiTownHall,
        iconPlay,
        iconMusic,
        iconBook,
      },
      map: 'https://maps.google.com/maps?q={{lat}},{{long}}&hl=es;z=14&output=embed',
      chartOptions: {
        colors: ['#00BB8D'],
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '35%',
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter: value => kFormatter(value, 0),
          },
        },
        grid: {
          strokeDashArray: 10,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          curve: 'smooth',
          width: 6,
          lineCap: 'round',
          colors: ['#fff'],
        },
        responsive: [
          {
            breakpoint: 1400,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '50%',
                },
              },
            },
          },
        ],
      },
      chartData: [
        {
          data: [],
        },
      ],
      libraries: [
        {
          icon: iconBook,
          title: 'E-Book',
          total: 0,
          ratio: 0,
          color: '#EC008C',
          keyName: 'count_ebook',
          keyRatioName: 'ratio_ebook',
        },
        {
          icon: iconMusic,
          title: 'Audio Book',
          total: 0,
          ratio: 0,
          color: '#7D2B8B',
          keyName: 'count_audio_book',
          keyRatioName: 'ratio_audio_book',
        },
        {
          icon: iconPlay,
          title: 'Video Book',
          total: 0,
          ratio: 0,
          color: '#F7B239',
          keyName: 'count_video_book',
          keyRatioName: 'ratio_video_book',
        },
      ],
      url: '',
      loadingCircle: true,
      instance: {},
      isLoadingInstance: false,
      serviceInstance: 'instance',
      service: 'dashboardinstance',
      instanceUuid: JSON.parse(localStorage.getItem('user')).user.company_uuid,
    }
  },
  created() {
    this.getDetailInstance()
    this.getDataCount({ company_uuid: JSON.parse(localStorage.getItem('user')).user.company_uuid })
  },
  methods: {
    async getDetailInstance() {
      this.isLoadingInstance = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.url = `https://maps.google.com/maps?q=${data.data.latitude},${data.data.longitude}&hl=es;z=14&output=embed`
          this.isLoadingInstance = false
        },
        err => {
          console.error(err)
          this.isLoadingInstance = false
        },
      )

      this.isLoadingInstance = false
    },

    async getDataCount(params) {
      this.loadingCircle = true

      await this.$services.ApiServices.list(this.service, { ...params }).then(({ data }) => {
        this.instance.total_ratio_visitors_month = data.total_ratio_visitors_month
        this.instance.total_visitors_month = data.total_visitors_month
        const chartData = [
          {
            data: [],
          },
        ]
        chartData[0].data = data.user_activities.map(el => ({ x: el.month, y: el.total_activity }))
        this.chartData = chartData

        Object.keys(data.library_company).forEach((key, item) => {
          this.libraries.forEach(el => {
            if (el.keyName === key) {
              el.total = data.library_company[key]
            }
            if (el.keyRatioName === key) {
              el.ratio = data.library_company[key]
            }
          })
        })
      })

      this.loadingCircle = false
    },
  },
}
</script>

<style>
.card-info,
.card-img {
  z-index: 1;
}
.card-info .font-weight,
.card-info .font-light {
  color: #fff;
}
.chartdiv {
  width: 100%;
  height: 320px;
}
.gamification-john-pose-2 {
  position: absolute;
  bottom: 0;
  right: 5%;
}

.match-height > v-col {
  display: flex;
  flex-flow: column;
}
.match-height > v-col > v-card {
  flex: 1 1 auto;
}
.select-year > .v-input__control > .v-text-field__details {
  display: none !important;
}
</style>
