const dailybook = [
  {
    path: '/daily-book',
    name: 'daily-book',
    component: () => import('@/views/daily-book/DailyBook.vue'),
    meta: {
      layout: 'content',
      resource: 'DailyBookAdmin',
      action: 'read',
    },
  },
]

export default dailybook
