import ApiServices from './ApiServices'
import AuthServices from './AuthServices'
import FcmServices from './FcmServices'
import NotificationsServices from './NotificationsServices'
import RecapServices from './RecapServices'

export default {
  ApiServices,
  AuthServices,
  FcmServices,
  NotificationsServices,
  RecapServices,
}
