import AttendancesRepository from './AttendancesRepository'
import AuthRepository from './AuthRepository'
import BankIconPostRepository from './BankIconPostRepository'
import BankIconRepository from './BankIconRepository'
import CategoryLibraryRepository from './CategoryLibraryRepository'
import CategoryRepository from './CategoryRepository'
import ClassRepository from './ClassRepository'
import DailyBookRepository from './DailyBookRepository'
import DashboardInstanceRepository from './DashboardInstanceRepository'
import DashboardRepository from './DashboardRepository'
import DistrictRepository from './DistrictRepository'
import EmployeeRepository from './EmployeeRepository'
import FaqRepository from './FaqRepository'
import FaqUserRepository from './FaqUserRepository'
import FcmRepository from './FcmRepository'
import GalleryRepository from './GalleryRepository'
import InstanceLibraryRepository from './InstanceLibraryRepository'
import InstanceOrderRepository from './InstanceOrderRepository'
import InstanceRepository from './InstanceRepository'
import InstanceSettingRepository from './InstanceSettingRepository'
import LayoutRepository from './LayoutRepository'
import LibraryRepository from './LibraryRepository'
import NotificationsRepository from './NotificationsRepository'
import PackageLibraryRepository from './PackageLibraryRepository'
import PackageRepository from './PackageRepository'
import ProvinceRepository from './ProvinceRepository'
import RecapRepository from './RecapRepository'
import RegencyRepository from './RegencyRepository'
import SliderRepository from './SliderRepository'
import SnapBookRepository from './SnapBookRepository'
import StudentRepository from './StudentRepository'
import TagIconRepository from './TagIconRepository'
import TransactionCompanyRespository from './TransactionCompanyRespository'
import TransactionRepository from './TransactionRepository'
import UserCompanyRepository from './UserCompanyRepository'
import UserRepository from './UserRepository'
import VillageRepository from './VillageRepository'

export default {
  AuthRepository,
  DailyBookRepository,
  DashboardRepository,
  DistrictRepository,
  InstanceSettingRepository,
  PackageRepository,
  ProvinceRepository,
  InstanceRepository,
  TransactionRepository,
  CategoryRepository,
  CategoryLibraryRepository,
  InstanceLibraryRepository,
  LibraryRepository,
  InstanceOrderRepository,
  PackageLibraryRepository,
  SliderRepository,
  SnapBookRepository,
  DashboardInstanceRepository,
  TransactionCompanyRespository,
  BankIconRepository,
  BankIconPostRepository,
  TagIconRepository,
  FcmRepository,
  NotificationsRepository,
  StudentRepository,
  ClassRepository,
  EmployeeRepository,
  UserCompanyRepository,
  AttendancesRepository,
  RecapRepository,
  RegencyRepository,
  FaqRepository,
  FaqUserRepository,
  GalleryRepository,
  UserRepository,
  LayoutRepository,
  VillageRepository,
}
