<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <user-bio-panel
          :instance-uuid="instanceUuid"
        ></user-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          fixed-tabs
          class="user-tabs"
        >
          <v-tab
            v-for="(tab,index) in tabs"
            :key="index"
            @click="reRenderComponent(index)"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-tab-overview
              :key="componentKey"
              :instance-uuid="instanceUuid"
            ></user-tab-overview>
          </v-tab-item>

          <v-tab-item>
            <user-tab-security
              :key="componentKey1"
              :user-uuid="userUuid"
              :instance-uuid="instanceUuid"
            ></user-tab-security>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import UserBioPanel from './user-bio-panel/UserBioPanel.vue'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiLockOutline } from '@mdi/js'
import UserTabOverview from './user-tab-overview/UserTabOverview.vue'
import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'

export default {
  components: {
    UserBioPanel,
    UserTabOverview,
    UserTabSecurity,
  },
  data() {
    return {
      componentKey: 0,
      componentKey1: 0,
      userData: [],
      instanceUuid: this.$route.params.uuid,
      userUuid: JSON.parse(localStorage.getItem('user')).user.uuid,
      userTab: '',
      tabs: [
        { icon: mdiAccountOutline, title: 'Overview' },
        { icon: mdiLockOutline, title: 'Keamanan' },
      ],
    }
  },
  methods: {
    reRenderComponent(index) {
      if (index === 0) {
        this.componentKey += 1
      } else {
        this.componentKey1 += 1
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
