<template>
  <div class="school-packet-book mt-2 mx-3">
    <div v-if="dialog ==='add'">
      <div
        v-if="!pkg"
        class="text-center mt-5 mb-10"
      >
        <v-progress-circular
          color="primary"
          class="mx-3 mb-3"
          :size="40"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-else
        class="school-packet-header d-flex justify-start flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row px-0 py-4"
      >
        <div
          class="d-flex justify-start me-4"
        >
          <ul>
            <li>Nama Paket</li>
            <li>Deskripsi </li>
          </ul>
          <ul>
            <li><span class="font-weight-bold">: {{ pkg.name }}</span></li>
            <li><span class="font-weight-bold">: {{ pkg.description }}</span></li>
          </ul>
        </div>
        <div class="d-flex justify-start">
          <ul>
            <li>Harga</li>
            <li>Jumlah Buku</li>
          </ul>
          <ul>
            <li><span class="font-weight-bold">: Rp. {{ pkg.price }}</span></li>
            <li><span class="font-weight-bold">: {{ pkg.library.length }}</span></li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="dialog ==='edit'">
      <v-progress-circular
        v-if="!instance"
        color="primary"
        class="mx-3 mb-3"
        :size="40"
        indeterminate
      ></v-progress-circular>
      <div
        class="school-packet-header d-flex justify-content-between px-0 py-4"
      >
        <div
          class="d-flex justify-content-between"
        >
          <ul>
            <li>ID Instansi</li>
            <li>Nama Instansi </li>
          </ul>
          <ul>
            <li><span class="font-weight-bold">: {{ instance.identity }}</span></li>
            <li><span class="font-weight-bold">: {{ instance.name }}</span></li>
          </ul>
        </div>
        <div class="d-flex justify-content-between">
          <ul>
            <li>Tanggal Bergabung</li>
            <li>Tipe Instansi</li>
          </ul>
          <ul>
            <li><span class="font-weight-bold">: {{ dateFormat(instance.user.created_at) }}</span></li>
            <li><span class="font-weight-bold">: {{ instance.company_types_uuid.type }}</span></li>
          </ul>
        </div>
      </div>
    </div>

    <v-divider></v-divider>
    <div class="school-packet-title d-flex align-center justify-space-between px-0 py-4">
      <v-col
        cols="5"
        class="px-0"
      >
        <h2>Daftar Paket Buku</h2>
        <p class="text-body-2 mt-1">
          Daftar buku yang telah ditentukan oleh sistem tidak dapat ubah
        </p>
      </v-col>
    </div>
    <div class="d-flex align-center justify-space-between px-0 mb-0">
      <v-col
        cols="5"
        class="px-0"
      >
        <v-card>
          <v-card-title>
            Paket
          </v-card-title>
          <v-divider></v-divider>
          <v-list
            height="400"
            class="overflow-auto"
          >
            <div
              v-if="isLoadingPackage && !packages.length"
              class="d-flex justify-center mt-5"
            >
              <v-progress-circular
                color="primary"
                class="mt-5"
                :size="50"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-list-item
              v-for="pack in packages"
              :key="pack.uuid"
              :class="package_uuid === pack.uuid ? 'card-active': ''"
              class="select-list-packet"
              @click="dialog ==='edit' ? getTransaction(pack.uuid) : getPackage(pack.uuid)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <a
                    class="d-flex justify-space-between align-center"
                  >
                    <span :class="package_uuid === pack.uuid ? 'white--text' : 'black--text'">{{ dialog ==='edit' ? pack.package: pack.name }}</span>
                    <v-chip
                      :color="package_uuid === pack.uuid ? 'white' : 'primary'"
                    >
                      <span :class="package_uuid === pack.uuid ? 'primary--text' : 'white--text'">{{ dialog === 'edit' ? pack.item_count : pack.library.length }}</span>
                    </v-chip>
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="7"
        class="pr-0"
      >
        <v-card>
          <v-card-title>Pilih Buku</v-card-title>
          <!-- <v-divider></v-divider> -->
          <v-simple-table
            height="400"
            class="overflow-auto"
          >
            <template v-slot:default>
              <thead style="border-top: none">
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    Name
                  </th>
                </tr>
              </thead>
              <tbody>
                <div
                  v-if="isLoadingLibrary"
                  class="text-center d-flex justify-center mt-5"
                >
                  <v-progress-circular
                    color="primary"
                    class="mt-5"
                    :size="50"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <tr
                  v-for="(lib,index) in packageLibrary"
                  :key="lib.uuid"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ lib.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </div>
    <div class="d-flex justify-center mt-4 mb-1">
      <v-btn
        v-if="dialog ==='edit'"
        :disabled="isLoadingButton"
        :loading="isLoadingButton"
        color="primary"
        class="mx-2"
        large
        @click="updateInstance"
      >
        Simpan
      </v-btn>
      <v-btn
        v-if="dialog ==='add'"
        :disabled="isLoadingButton"
        :loading="isLoadingButton"
        color="primary"
        class="mx-2"
        large
        @click="addInstance"
      >
        Simpan
      </v-btn>
      <v-btn
        color="primary"
        outlined
        class="mx-2"
        large
        @click="prevTab()"
      >
        Sebelumnya
      </v-btn>
    </div>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Paket"
      @close-button="confirmDialog = false"
      @destroy-button="destroyPacket"
    >
      <template v-slot:body>
        Apakah anda yakin ingin menghapus paket "" ?
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import dateTimeFormat from '@/utils/date/dateTimeFormat'
import ConfirmDialog from '../components/ConfirmDialog.vue'

export default {
  name: 'InstancePacketBook',
  components: {
    ConfirmDialog,
  },
  props: {
    instance: {
      type: Object,
      required: true,
    },
    dialog: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      isLoadingLibrary: false,
      confirmDialog: false,
      searchDebounce: '',
      categories: [],
      libraries: [],
      notices: [
        { title: 'Buku yang akan dibeli', isChecked: true },
        { title: 'Buku yang sudah dibeli', isChecked: true },
        { title: 'Buku yang tidak dibeli', isChecked: false },
      ],
      packet: [],
      packets: [],
      package_uuid: '',
      packetDetail: {},
      isLoadingButton: false,
      isDisabledButton: false,
      timeout: null,
      totalBook: 0,
      librariesToAdd: [],
      librariesToRemove: [],
      librariesSelected: [],
      cardActive: false,
      isEmpty: false,
      servicePackage: 'package',
      serviceInstance: 'instance',
      serviceTransaction: 'transaction',
      packages: [],
      pkg: null,
      packageLibrary: [],
    }
  },
  computed: {},
  watch: {
    instance: {
      handler() {
        if (this.dialog === 'edit') {
          this.replayFetch()
        } else {
          this.listPackage()
          this.getPackage(this.instance.package_uuid)
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.dialog === 'add') {
      this.listPackage()
    } else {
      this.replayFetch()
    }
    // console.log(this.instance)
  },
  mounted() {
    if (this.dialog === 'add') {
      this.getPackage(this.instance.package_uuid)
    }
    // this.getPackage(this.instance.package_uuid)
  },
  methods: {
    async listPackage(params = {}) {
      this.isLoadingPackage = true
      await this.$services.ApiServices.list(this.servicePackage, {
        ...params,
        per_page: 'all',
      }).then(
        ({ data }) => {
          this.packages = data.data
          this.isLoadingPackage = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingPackage = false
    },
    async updateInstance() {
      this.isLoadingButton = true
      this.formData = new FormData()

      Object.keys(this.instance).forEach((key, item) => {
        this.formData.append(key, this.instance[key])
        if (
          // eslint-disable-next-line operator-linebreak
          this.instance[key] === '' ||
          // eslint-disable-next-line operator-linebreak
          this.instance[key] === null ||
          // eslint-disable-next-line operator-linebreak
          typeof this.instance[key] === 'undefined' ||
          typeof this.instance[key] === 'object'
        ) {
          this.formData.delete(key)
        }
        this.formData.delete('thumbnail')
      })

      await this.$services.ApiServices.update(this.serviceInstance, this.formData, this.instance.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Update Instance Has Successfully',
            color: 'success',
          })
          this.isLoadingButton = false
          this.$emit('close-dialog')
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },
    async addInstance() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.serviceInstance, this.instance).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Add Instance Has Successfully',
            color: 'success',
          })
          this.isLoadingButton = false
          this.$emit('close-dialog')
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingButton = false
    }, // end of addInstance
    async getTransaction(uuid) {
      this.packageLibrary = []
      this.isLoadingLibrary = true
      this.package_uuid = uuid
      await this.$services.ApiServices.get(this.serviceTransaction, uuid).then(
        ({ data }) => {
          this.packageLibrary = data.data.library
          this.isLoadingLibrary = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingLibrary = false
    },
    async getPackage(uuid) {
      this.pkg = null
      this.packageLibrary = []
      this.isLoadingLibrary = true
      this.package_uuid = uuid
      await this.$services.ApiServices.get(this.servicePackage, uuid).then(
        ({ data }) => {
          this.pkg = data.data
          this.packageLibrary = data.data.library
          this.isLoadingLibrary = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingLibrary = false
    },
    prevTab() {
      this.$emit('prev-tab')
      this.packetDetail = {}
      this.cardActive = false
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    dateFormat(val) {
      return dateTimeFormat(val, '/', true, false)
    },
    replayFetch() {
      this.isLoadingPackage = true
      this.packageLibrary = []
      this.package_uuid = null
      this.packages = this.instance.transaction_history
      this.isLoadingPackage = false
    },
  },
}
</script>

<style scoped>
.school-packet-header .header-left ul {
  margin-left: 0;
  /* border: 1px solid #000; */
}
.school-packet-header ul li {
  list-style: none;
  margin-bottom: 1rem;
}
.card-active {
  /* outline: 1px solid #00bb8d !important; */
  background: #00bb8d !important;
}

.select-list-packet.card-active {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.text-color {
  color: #00bb8d;
}

.v-application a {
  color: #746d7d;
  text-decoration: none;
}
</style>
