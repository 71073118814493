<template>
  <div class="school-packet-book mt-3 mx-3">
    <div>
      <v-progress-circular
        v-if="!instance"
        color="primary"
        class="mx-3 mb-3"
        :size="40"
        indeterminate
      ></v-progress-circular>
      <div
        v-else
        class="school-packet-header d-flex justify-content-between px-0 py-4"
      >
        <div
          class="d-flex justify-content-between"
        >
          <ul>
            <li>ID Instansi</li>
            <li>Nama Instansi </li>
          </ul>
          <ul>
            <li><span class="font-weight-bold">: {{ instance.identity }}</span></li>
            <li><span class="font-weight-bold">: {{ instance.name }}</span></li>
          </ul>
        </div>
        <div class="d-flex justify-content-between">
          <ul>
            <li>Tanggal Bergabung</li>
            <li>Tipe Instansi</li>
          </ul>
          <ul>
            <li><span class="font-weight-bold">: {{ dateFormat(instance.user.created_at) }}</span></li>
            <li><span class="font-weight-bold">: {{ instance.company_types_uuid.type }}</span></li>
          </ul>
        </div>
      </div>
    </div>

    <v-divider></v-divider>
    <div class="school-packet-title d-flex align-center justify-space-between px-0 py-4">
      <v-col
        cols="5"
        class="px-0"
      >
        <h2>Daftar Paket Buku</h2>
        <p class="text-body-2 mt-1">
          Daftar buku yang telah ditentukan oleh sistem tidak dapat ubah
        </p>
      </v-col>
    </div>
    <div class="d-flex align-center justify-space-between px-0 mb-0">
      <v-col
        cols="5"
        class="px-0"
      >
        <v-card>
          <v-card-title>
            Paket
          </v-card-title>
          <v-divider></v-divider>
          <v-list
            height="400"
            class="overflow-auto"
          >
            <div
              v-if="isLoadingPackage && !packages.length"
              class="d-flex justify-center mt-5"
            >
              <v-progress-circular
                color="primary"
                class="mt-5"
                :size="50"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-list-item
              v-for="pack in packages"
              :key="pack.uuid"
              :class="package_uuid === pack.uuid ? 'card-active': ''"
              @click="getTransaction(pack.uuid) "
            >
              <v-list-item-content>
                <v-list-item-title>
                  <a
                    class="d-flex justify-space-between align-center"
                  >
                    <span :class="package_uuid === pack.uuid ? 'white--text' : 'black--text'">{{ pack.package }}</span>
                    <v-chip
                      :color="package_uuid === pack.uuid ? 'white' : 'primary'"
                    >
                      <span :class="package_uuid === pack.uuid ? 'primary--text' : 'white--text'">{{ pack.item_count }}</span>
                    </v-chip>
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="7"
        class="pr-0"
      >
        <v-card>
          <v-card-title>Pilih Buku</v-card-title>
          <!-- <v-divider></v-divider> -->
          <v-simple-table
            height="400"
            class="overflow-auto"
          >
            <template v-slot:default>
              <thead style="border-top: none">
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    Name
                  </th>
                </tr>
              </thead>
              <tbody>
                <div
                  v-if="isLoadingLibrary"
                  class="d-flex justify-center mt-5"
                >
                  <v-progress-circular
                    color="primary"
                    class="mt-5"
                    :size="50"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <tr
                  v-for="(lib,index) in packageLibrary"
                  :key="lib.uuid"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ lib.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </div>

    <!-- <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Paket"
      @close-button="confirmDialog = false"
      @destroy-button="destroyPacket"
    >
      <template v-slot:body>
        Apakah anda yakin ingin menghapus paket "" ?
      </template>
    </ConfirmDialog> -->
  </div>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog.vue'
import dateTimeFormat from '@/utils/date/dateTimeFormat'

export default {
  name: 'InstanceHistory',
  components: {},
  props: {
    instanceUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadingLibrary: false,
      confirmDialog: false,
      searchDebounce: '',
      categories: [],
      libraries: [],
      notices: [
        { title: 'Buku yang akan dibeli', isChecked: true },
        { title: 'Buku yang sudah dibeli', isChecked: true },
        { title: 'Buku yang tidak dibeli', isChecked: false },
      ],
      packet: [],
      packets: [],
      package_uuid: '',
      packetDetail: {},
      isLoadingButton: false,
      isLoadingPackage: false,
      isDisabledButton: false,
      timeout: null,
      totalBook: 0,
      cardActive: false,
      isEmpty: false,
      servicePackage: 'package',
      serviceInstance: 'instance',
      serviceTransaction: 'transaction',
      packages: [],
      pkg: null,
      packageLibrary: [],
      instance: null,
    }
  },
  computed: {},
  watch: {
    instanceUuid: {
      handler() {
        if (this.instanceUuid) {
          this.loadReset()
          this.getInstance(this.instanceUuid)
        }
      },
    },
  },
  created() {
    this.getInstance(this.instanceUuid)
  },
  mounted() {},
  methods: {
    async getInstance() {
      this.isLoadingPackage = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.packages = data.data.transaction_history
          this.isLoadingPackage = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingPackage = false
    },
    async getTransaction(uuid) {
      this.packageLibrary = []
      this.isLoadingLibrary = true
      this.package_uuid = uuid
      await this.$services.ApiServices.get(this.serviceTransaction, uuid).then(
        ({ data }) => {
          this.packageLibrary = data.data.library
          this.isLoadingLibrary = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingLibrary = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    dateFormat(val) {
      return dateTimeFormat(val, '/', true, false)
    },
    loadReset() {
      this.instance = null
      this.packageLibrary = []
      this.package_uuid = null
    },
  },
}
</script>

<style scoped>
.school-packet-header .header-left ul {
  margin-left: 0;
  /* border: 1px solid #000; */
}
.school-packet-header ul li {
  list-style: none;
  margin-bottom: 1rem;
}
.card-active {
  /* outline: 1px solid #00bb8d !important; */
  background: #00bb8d !important;
}

.text-color {
  color: #00bb8d;
}

.v-application a {
  color: #746d7d;
  text-decoration: none;
}
</style>
