<template>
  <div>
    <v-dialog
      v-model="detailDialog"
      persistent
      width="1080"
    >
      <v-card>
        <v-card-title
          class="headline justify-center"
        >
          Daftar Buku
        </v-card-title>
        <v-icon
          style="position:absolute;top:20px;right:20px"
          @click="handleCloseDialog"
        >
          {{ icons.mdiClose }}
        </v-icon>
        <div
          class="book-packet-book mt-3 mx-6 pb-5"
        >
          <div>
            <v-progress-circular
              v-if="!transactionDetail"
              color="primary"
              class="mx-3 mb-3"
              :size="40"
              indeterminate
            ></v-progress-circular>
            <v-row
              v-else
              class="book-packet-header px-0 py-4 school-packet-header"
            >
              <v-col
                xl="4"
                lg="4"
                md="4"
                sm="6"
                cols="12"
                class="d-flex justify-start"
              >
                <ul class="pa-0">
                  <li><span class="font-weight-bold">Tanggal Pembelian </span></li>
                  <li><span class="font-weight-bold">Tipe Buku </span></li>
                </ul>
                <ul>
                  <li><span class="font-weight-bold">: {{ dateFormat(transactionDetail.created_at) }}</span></li>
                  <li><span class="font-weight-bold">: Ebook</span></li>
                </ul>
              </v-col>
              <v-col
                xl="4"
                lg="4"
                md="4"
                sm="6"
                cols="12"
                class="d-flex justify-start"
              >
                <ul class="pa-0">
                  <li><span class="font-weight-bold">Paket </span></li>
                  <li><span class="font-weight-bold">Jumlah </span></li>
                </ul>
                <ul>
                  <li><span class="font-weight-bold">: {{ transactionDetail.package }}</span></li>
                  <li><span class="font-weight-bold">: {{ transactionDetail.item_count }}</span></li>
                </ul>
              </v-col>
              <v-col
                xl="4"
                lg="4"
                md="4"
                sm="6"
                cols="12"
                class="d-flex justify-start"
              >
                <div class="pa-0">
                  <ul>
                    <li><span class="font-weight-bold">Harga </span></li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>
                      <span class="font-weight-bold"> : Rp. {{ transactionDetail.price }}</span>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <div class="d-flex align-center justify-space-between mb-6">
              <v-col
                cols="6"
                class="pl-0"
              >
                <h2>Daftar Buku</h2>
                <p class="mt-1 mb-0">
                  Daftar buku ditampilkan pertanggal pengembalian
                </p>
              </v-col>

              <v-col
                cols="6"
                class="pr-0"
              >
                <v-text-field
                  v-model="search"
                  label="Cari Buku"
                  dense
                  outlined
                  class="search-book"
                ></v-text-field>
              </v-col>
            </div>
            <v-card>
              <v-card-text class="pa-0">
                <h4 class="pa-4">
                  List Buku
                </h4>
                <v-progress-linear
                  v-if="isLoadingDetail"
                  color="primary"
                  :size="50"
                  indeterminate
                  style="width: 100%"
                ></v-progress-linear>
                <v-simple-table
                  v-else
                  height="auto"
                  class="overflow-auto"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          No
                        </th>
                        <th class="text-left">
                          Nama Buku
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(lib,index) in filteredList"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ lib.name }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dateTimeFormat from '@/utils/date/dateTimeFormat'
import { mdiClose, mdiDotsVertical, mdiTownHall } from '@mdi/js'

export default {
  name: 'Library',
  components: {},
  props: {
    packageUuid: {
      type: String,
      default: () => '',
    },
    detailDialog: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      search: '',
      isLoadingDetail: false,
      icons: {
        mdiDotsVertical,
        mdiTownHall,
        mdiClose,
      },
      serviceTransaction: 'transaction',
      transactionDetail: null,
      filtered: [],
    }
  },
  computed: {
    filteredList() {
      return this.setArchive()
    },
  },
  watch: {
    packageUuid: {
      handler() {
        if (this.packageUuid) {
          this.getTransactionDetail()
        }
      },
    },
  },
  created() {},
  methods: {
    setArchive() {
      const { library } = this.transactionDetail
      if (library) {
        this.filtered = library.filter(lib => lib.name.toLowerCase().includes(this.search.toLowerCase()))
      }

      return this.filtered
    },

    async getTransactionDetail() {
      this.isLoadingDetail = true

      await this.$services.ApiServices.get(this.serviceTransaction, this.packageUuid).then(
        ({ data }) => {
          this.transactionDetail = data.data
          this.isLoadingDetail = false
        },
        err => {
          console.error(err)
          this.isLoadingDetail = false
        },
      )

      this.isLoadingDetail = false
    },

    handleCloseDialog() {
      this.transactionDetail = null
      this.$emit('close-dialog')
    },

    dateFormat(val) {
      return dateTimeFormat(val, '/', false, false)
    },
  },
}
</script>

<style>
.school-packet-header .header-left ul {
  margin-left: 0;
  /* border: 1px solid #000; */
}

.school-packet-header ul li {
  list-style: none;
  margin-bottom: 1rem;
}

.card-active {
  /* outline: 1px solid #00bb8d !important; */
  background: #00bb8d !important;
}

.text-color {
  color: #00bb8d;
}
</style>
