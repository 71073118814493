<template>
  <div class="packet-form">
    <v-container class="pb-0">
      <v-row class="py-5">
        <v-col
          cols="6"
          class="pl-0"
        >
          <div>
            <v-text-field
              v-model="packet.name"
              label="Nama Paket"
              dense
              :loading="!packet.name.length && dialog === 'edit'"
              :disabled="!packet.name.length && dialog === 'edit'"
              type="text"
              outlined
              :rules="[validation.required]"
            ></v-text-field>
          </div>
          <div>
            <v-text-field
              v-model="fPrice"
              label="Harga"
              dense
              :loading="!packet.price.length && dialog === 'edit'"
              :disabled="!packet.price.length && dialog === 'edit'"
              type="text"
              outlined
              :rules="[validation.required]"
              onkeypress="return /[0-9]/i.test(event.key)"
            ></v-text-field>
          </div>
          <!-- <div>
            <v-autocomplete
              v-model="packet.level"
              :items="levels"
              label="Jenjang"
              dense
              outlined
              item-text="name"
              :rules="[validation.required]"
              item-value="uuid"
            >
            </v-autocomplete>
          </div> -->
        </v-col>
        <v-col
          cols="6"
          class="pr-0"
        >
          <v-textarea
            v-model="packet.description"
            :rules="[validation.required]"
            label="Deskripsi"
            :loading="!packet.description.length && dialog === 'edit'"
            :disabled="!packet.description.length && dialog === 'edit'"
            dense
            outlined
            height="175"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <div class="d-flex justify-center mb-1">
        <v-btn
          color="primary"
          outlined
          class="mx-2"
          large
          @click="closeHandler"
        >
          Batal
        </v-btn>
        <v-btn
          color="primary"
          class="mx-2"
          large
          :disabled="!formValid"
          @click="add"
        >
          Selanjutnya
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'
import { integerValidator, required } from '@core/utils/validation'

export default {
  props: {
    packageUuid: {
      type: String,
      default() {
        return ''
      },
    },
    dialog: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      levels: [],
      packet: {
        uuid: '',
        name: '',
        price: '',
        total_library: null,
        description: '',
      },
      search: '',
      validation: {
        required,
        integerValidator,
      },
      formValid: false,
      servicePackage: 'package',
    }
  },
  computed: {
    fPrice: {
      get() {
        const formatedPrice = toCurrencyFormat(this.packet.price)

        return formatedPrice === 'NaN' ? null : formatedPrice
      },
      set(newValue) {
        const formatedPrice = toCurrencyFormat(newValue)
        this.packet.price = formatedPrice === 'NaN' ? null : formatedPrice
      },
    },
  },
  watch: {
    packageUuid: {
      handler() {
        if (this.packageUuid) {
          this.getPackage()
        }
        this.resetPackage()
      },
      deep: true,
    },
    dialog: {
      handler() {
        if (this.dialog === 'add') {
          this.resetPackage()
        }
      },
      deep: true,
    },
    packet: {
      handler() {
        const valid = []
        const requiredField = ['name', 'price', 'description']
        Object.entries(this.packet).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
        this.$emit('component-valid', this.formValid)
      },
      deep: true,
    },
  },
  mounted() {
    if (this.packageUuid) {
      this.getPackage()
    }
  },
  methods: {
    resetPackage() {
      this.packet.name = ''
      this.packet.total_library = null
      this.packet.price = ''
      this.packet.description = ''
    },
    add() {
      this.$emit('next-tab', this.packet)
    },
    async getPackage() {
      this.$services.ApiServices.get(this.servicePackage, this.packageUuid).then(
        ({ data }) => {
          this.packet = {
            uuid: data.data.uuid,
            name: data.data.name,
            price: data.data.price,
            total_book: data.data.total_library,
            description: data.data.description,
          }
        },
        err => console.error(err),
      )
    },
    closeHandler() {
      this.$emit('close-dialog')
      this.resetPackage()
    },
  },
}
</script>

<style>
</style>
