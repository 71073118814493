import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/company'

export default {
  recapStudent(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${uuid}/attendance/recap${paramsString}`, {
      responseType: 'blob',
      contentType: 'application/pdf',
    })
  },
  recapUser(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${uuid}/users/recap${paramsString}`, {
      responseType: 'blob',
      contentType: 'application/pdf',
    })
  },
  recapEmployee(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${uuid}/employee/recap${paramsString}`, {
      responseType: 'blob',
      contentType: 'application/pdf',
    })
  },
  recap(uuid, params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}/${uuid}/users/recap${paramsString}`, {
      responseType: 'blob',
      contentType: 'application/pdf',
    })
  },
}
